import React, { useEffect, useRef, useState, useContext } from "react";
import {
  Button,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Tooltip,
} from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import { Grid } from "@mui/material";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import "./ServicesBoxGrid.css";
import { replaceSpaceWithDash } from "../../../utils/custom";
import { t } from "i18next";
import AuthContext from '../../../context/AuthContext'

function ServicesBoxGrid(props) {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);
  const currentUrl = window.location.hash.split("/");
  const serviceName = currentUrl[1];
  const [infoPopup, setInfoPopup] = useState({
    status: false,
    detail: {},
  });
  const [scroll, setScroll] = useState("paper");
  const descriptionElementRef = useRef(null);
  const navigate = useNavigate();
  const authCtx = useContext(AuthContext);

  useEffect(() => {
    // setTimeout(() => {

    const apiData = props?.setService || [];
    setData(apiData);
    setLoading(false);
    // }, 3000);
  }, [props.setService]);

  const fisrtCatgory = (cat) => {
    const array = cat.split(",");
    const category = replaceSpaceWithDash(array[0]);
    return category;
  };

  const handleInfoPopupClose = () => {
    setInfoPopup({
      status: false,
      detail: {},
    });
  };

  const handleInfoPopupOpen = (e, scrollType, service) => {
    console.log(service);
    e.preventDefault();
    e.stopPropagation();
    setInfoPopup({
      status: true,
      detail: service,
    });;
    setScroll(scrollType);
  };

  const handleClick = (item) => {
    if (item.id === 1 || item.id === 2 || item.id === 3 || item.id === 4 || item.id === 5) {
      navigate(`/detail/services/${props.search ? fisrtCatgory(item.category_name)
        : serviceName}/${replaceSpaceWithDash(item.name)}`, { state: { anonymous_user: item.anonymous_user, } })
    }
  };

  const HandleNavigation = (item) => {
    /*  */
    if (item.service_type === 1 || item.service_type === "1") {
      if (item.reference_number !== null && item.reference_number !== "") {
        if (authCtx.isLogin) {
          navigate(`/all-services-detail/${replaceSpaceWithDash(item.name)}`, { state: { mdaName: props.search ? item.category_name : serviceName, serviceId: item.reference_number } })
        } else {
          navigate("/login", { state: { from: 3, navigateUrl: `/all-services-detail/${replaceSpaceWithDash(item.name)}`, mdaName: props.search ? item.category_name : serviceName, serviceId: item.reference_number } });
        }
      } else {
        if (authCtx.isLogin) {
          navigate(`/all-services-detail/${replaceSpaceWithDash(item.name)}`, { state: { mdaName: props.search ? item.category_name : serviceName, serviceId: item.id } })
        } else {
          navigate("/login", { state: { from: 3, navigateUrl: `/all-services-detail/${replaceSpaceWithDash(item.name)}`, mdaName: props.search ? item.category_name : serviceName, serviceId: item.id } });
        }
      }
    } else {
      if (item.id === 1 || item.id === 2 || item.id === 3 || item.id === 4 || item.id === 5) {
        navigate(`/detail/services/${props.search ? fisrtCatgory(item.category_name) : serviceName}/${replaceSpaceWithDash(item.name)}`, { state: { anonymous_user: item.anonymous_user, } })
      }
    }
  };

  const HandlePopupNavigation = () => { 
    if (infoPopup.detail.service_type === 1 || infoPopup.detail.service_type === "1") {
      if (infoPopup.detail.reference_number !== null && infoPopup.detail.reference_number !== "") {
        if (authCtx.isLogin) {
          navigate(`/all-services-detail/${replaceSpaceWithDash(infoPopup.detail.name)}`, { state: { mdaName: props.search ? infoPopup.detail.category_name : serviceName, serviceId: infoPopup.detail.reference_number } })
        } else {
          navigate("/login", { state: { from: 3, navigateUrl: `/all-services-detail/${replaceSpaceWithDash(infoPopup.detail.name)}`, mdaName: props.search ? infoPopup.detail.category_name : serviceName, serviceId: infoPopup.detail.reference_number } });
        }
      } else {
        if (authCtx.isLogin) {
          navigate(`/all-services-detail/${replaceSpaceWithDash(infoPopup.detail.name)}`, { state: { mdaName: props.search ? infoPopup.detail.category_name : serviceName, serviceId: infoPopup.detail } })
        } else {
          navigate("/login", { state: { from: 3, navigateUrl: `/all-services-detail/${replaceSpaceWithDash(infoPopup.detail.name)}`, mdaName: props.search ? infoPopup.detail.category_name : serviceName, serviceId: infoPopup.detail } });
        }
      }
    } else {
      if (infoPopup.detail.id === 1 || infoPopup.detail.id === 2 || infoPopup.detail.id === 3 || infoPopup.detail.id === 4 || infoPopup.detail.id === 5) {
        navigate(`/detail/services/${props.search ? fisrtCatgory(infoPopup.detail.category_name)
          : serviceName}/${replaceSpaceWithDash(infoPopup.detail.name)}`, { state: { anonymous_user: infoPopup.detail.anonymous_user, } })
      }
    }
  };

  return (
    <>
      <Container maxWidth="lg">
        <Grid container spacing={2}>
          {data.length !== 0 ? (
            data?.map((item) => (
              <Grid
                item
                xs={12}
                sm={6}
                md={4}
                lg={4}
                className="grid-boxes"
                key={item.id}
                id={item.id}
              >
                {props.detail ? (
                  <Link
                    to={`/${replaceSpaceWithDash(item.name)}/services/${
                      item.id
                    }`}
                  >
                    <div className="category-outer-box">
                      <div className="left-img">
                        <img
                          src={`${process.env.REACT_APP_HOST_URL}${process.env.REACT_APP_IMAGE_URL}${item.icon}`}
                          alt={item.name}
                        />
                      </div>
                      <div className="right-cont">
                        <Tooltip title={item.name} placement="top-start">
                          <h4>{item.name}</h4>
                        </Tooltip>
                        {/* <p>{item.description}</p> */}
                      </div>
                    </div>
                  </Link>
                ) : (
                  <div style={{ cursor: 'pointer' }}
                      onClick={() => HandleNavigation(item)} >
                    <div className="category-outer-box">
                      {item.content && (
                        <InfoOutlinedIcon
                          className="info-icon"
                          onClick={(e) => handleInfoPopupOpen(e, "paper", item)}
                        />
                      )}
                      <div className="left-img">
                        <img
                          src={`${process.env.REACT_APP_HOST_URL}${process.env.REACT_APP_IMAGE_URL}${item.icon}`}
                          alt={item.name}
                        />
                      </div>
                      <div className="right-cont">
                        <Tooltip title={item.name} placement="top-start">
                          <h4>{item.name}</h4>
                        </Tooltip>
                        <p>{item.description}</p>
                      </div>
                    </div>
                  </div>
                )}
              </Grid>
            ))
          ) : (
            <div className="no-data-found">
              <br />
              {t("no data text")}
              <br />
            </div>
          )}
        </Grid>
      </Container>
      {infoPopup.status && (
        <Dialog
          open={infoPopup.status}
          onClose={handleInfoPopupClose}
          scroll={scroll}
          aria-labelledby="scroll-dialog-title"
          aria-describedby="scroll-dialog-description"
          className="notify-popup"
          sx={{
            "& .MuiDialog-container": {
              "& .MuiPaper-root": {
                width: "100%",
                maxWidth: "700px", // Set your width here
              },
            },
          }}
        >
          <div style={{ width: "100%", maxWidth: "700px", }}>
            <DialogTitle id="scroll-dialog-title" className="head">
              {infoPopup.detail.name}
            </DialogTitle>
            <DialogContent dividers={scroll === "paper"}>
              <DialogContentText
                id="scroll-dialog-description"
                ref={descriptionElementRef}
                tabIndex={-1}
                dangerouslySetInnerHTML={{ __html: infoPopup.detail.content }}
              ></DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleInfoPopupClose} className="notify-close">
                {t("close text")}
              </Button>
              <div style={{ cursor: 'pointer' }}
                onClick={() => HandlePopupNavigation()}
                className="notify-proceed"
              >
                {t("proceed text")}
              </div>
            </DialogActions>
          </div>
        </Dialog>
      )}
    </>
  );
}

export default ServicesBoxGrid;
