import { Container, FormGroup, FormLabel, InputAdornment, TextField, Typography, Box , styled} from "@mui/material";
import React, { useState, useEffect, useContext } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Grid } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import axios from "axios";
import TablePagination from '@mui/material/TablePagination';
import { ALL_SERVICES_LIST } from "../../utils/api-constant";
import "./AllServices.css";
import { replaceSpaceWithDash } from "../../utils/custom";
import LoadingPage from "../SeviceScreen/LoadingPage";
import ErrorSection from "../Error/ErrorSection";
import RightArrow from "../../assets/Img/all_service_arrow.png";
import AuthContext from "../../context/AuthContext";

const StyledTextField = styled(TextField)({
  '& .MuiInputBase-input::placeholder': {
    fontSize: '14px',
    fontWeight:'500',
  },
  '& .MuiInputBase-input': {
    fontSize: '14px',
    fontWeight:'500',
  },
});

function AllServices() {

  const location = useLocation();
  const searchText = location.state?.searchText || "";
  console.log("searchText--> ", searchText)
  const { t } = useTranslation();
  const [AllServicesList, setAllServicesList] = useState([]);
  const [Page, setPage] = useState(0);
  const [RowsPerPage, setRowsPerPage] = useState(25);
  const [TotalCount, setTotalCount] = useState(0);
  const [SearchValue, setSearchValue] = useState(searchText);
  const [Loading, setLoading] = useState(true);
  const [Error, setError] = useState(null);
  const navigate = useNavigate();
  const authCtx = useContext(AuthContext);

  const getAllServicesList = (apiToken, search, start, limit) => {
    const params = {
      headers: {
        needJson: 1,
        apiToken: apiToken,
        "Content-Type": "application/json",
      },
    };
    axios
      .get(`${process.env.REACT_APP_HOST_URL + ALL_SERVICES_LIST}${search}&start=${start}&limit=${limit}`, params)
      .then((response) => {
        setTotalCount(response.data.count);
        setAllServicesList(response.data.list);
        setLoading(false);
      })
      .catch((e) => {
        setLoading(false);
        setError(e.message);
      });
  };

  useEffect(() => {
    let apiToken = JSON.parse(localStorage.getItem("apiToken"));
    getAllServicesList(apiToken, SearchValue, Page * RowsPerPage, RowsPerPage);
  }, [Page, RowsPerPage, SearchValue]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setPage(0);
    setRowsPerPage(parseInt(event.target.value, 10));
  };

  const HandleClear = () => {
    setPage(0);
    setSearchValue('');
  };

  const HandleSearch = (event) => {
    setPage(0);
    setSearchValue(event.target.value);
  };

  const fisrtCatgory = (cat) => {
    const array = cat.split(",");
    const category = replaceSpaceWithDash(array[0]);
    return category;
  };

  const HandleServiceClick = (serviceItem, mdaItem) => {
    if (serviceItem.service_type === 1 || serviceItem.service_type === "1"){
      if (serviceItem.reference_number !== null && serviceItem.reference_number !== ""){
        if (authCtx.isLogin) {
          if (serviceItem.reference_number === "98de7522-c0a9-391d-907c-e1d81b370e48"){
            navigate(`/all-services-detail/${replaceSpaceWithDash(serviceItem.serviceName)}/${'apply'}`, { state: { mdaName: mdaItem.mdaName, serviceId: serviceItem.reference_number } })
          } else if (serviceItem.reference_number === "718b9f88-af8b-3b87-be84-8f4880eebc6d") {
            navigate(`/all-services-detail/${replaceSpaceWithDash(serviceItem.serviceName)}/${'renew'}`, { state: { mdaName: mdaItem.mdaName, serviceId: serviceItem.reference_number } })
          } else {
            navigate(`/all-services-detail/${replaceSpaceWithDash(serviceItem.serviceName)}`, { state: { mdaName: mdaItem.mdaName, serviceId: serviceItem.reference_number } })
          }
          
        } else {
          navigate("/login", { state: { from: 3, navigateUrl: `/all-services-detail/${replaceSpaceWithDash(serviceItem.serviceName)}`, mdaName: mdaItem.mdaName, serviceId: serviceItem.reference_number } });
        }
      } else {
        if (authCtx.isLogin) {
          navigate(`/all-services-detail/${replaceSpaceWithDash(serviceItem.serviceName)}`, { state: { mdaName: mdaItem.mdaName, serviceId: serviceItem.serviceId } })
        } else {
          navigate("/login", { state: { from: 3, navigateUrl: `/all-services-detail/${replaceSpaceWithDash(serviceItem.serviceName)}`, mdaName: mdaItem.mdaName, serviceId: serviceItem.serviceId } });
        }
      }
    }else {
      if (serviceItem.serviceId === 1 || serviceItem.serviceId === 2 || serviceItem.serviceId === 3 || serviceItem.serviceId === 4 || serviceItem.serviceId === 5) {
        navigate(`/detail/mda/${fisrtCatgory(mdaItem.mdaName)}/${replaceSpaceWithDash(serviceItem.serviceName)}`, { state: { anonymous_user: 1, } })
      }
    }
  };

  if (Error) return <ErrorSection />;

  if (Loading) return <LoadingPage />;

  return (
    <div className="all-service list">
   
      <div className="all-service-header">
      <Container>
          <div className="all-service-header-inner">
            <div className="left-div">
              <h6 className="left-serviceall-text1">{t("all services")}</h6>
              <h6 className="left-serviceall-text2">{t("explore all our services")}</h6>
            </div>
          </div>
        </Container>
      </div>
      <Container>
      <section className="all-services-view">
       
          <div className="service-page-detail">
            <FormGroup className="form-group">
            <StyledTextField
                className="form-control search-field"
                type="text"
                size="small"
                placeholder={t("search your favorite services")}
                value={SearchValue}
                onChange={HandleSearch}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon sx={{ fontSize: 25 }} />
                    </InputAdornment>),
                  endAdornment: (
                    <InputAdornment position="end" >
                      <div onClick={HandleClear} style={{ cursor: 'pointer' }}>
                        <CloseRoundedIcon sx={{ fontSize: 25,paddingTop:1}}/>
                      </div>
                    </InputAdornment>)
                }}
                 />
            </FormGroup>
            <Grid container spacing={2} className="grid">
              {AllServicesList.length === 0 ? (
                <Grid item xs={12}>
                  <Typography variant="body1">No data found</Typography>
                </Grid>
              ) : (
                AllServicesList.map((item, index) => (
                  <Grid item xs={12} key={index}>
                    <FormLabel className="grid-text">{item.mdaName}</FormLabel>
                    <Grid container spacing={2} sx={{ marginTop: 0 }}>
                      {item.services.map((service, subIndex) => (
                        <Grid className="siblingService" item xs={12} sm={4} md={4} key={subIndex}>
                          <Box
                            onClick={() => HandleServiceClick(service, item)}
                            sx={{ display: 'flex', alignItems: 'center', cursor: 'pointer', padding: 1, marginTop: -1 }}
                          >
                            <img src={RightArrow} alt='' style={{ marginRight: 8 }} className="list-item-arrow" />
                            <Typography className="list-item-text" variant="body1">{service.serviceName}</Typography>
                          </Box>
                        </Grid>
                      ))}
                    </Grid>
                  </Grid>
                ))
              )}
            </Grid>

            {AllServicesList.length > 0 && (
              <TablePagination
                rowsPerPageOptions={[25, 50, 75, 100]}
                component="div"
                count={TotalCount}
                rowsPerPage={RowsPerPage}
                page={Page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            )}
          </div>
       
      </section>
      </Container>
    </div>
  );
}

export default AllServices;