import React, { useState, useEffect, useContext } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import axios from "axios";
import { LOGIN_URL } from "../../../utils/api-constant";
import Grid from "@mui/material/Grid";
import Container from "@mui/material/Container";
import FormGroup from "@mui/material/FormGroup";
import FormLabel from "@mui/material/FormLabel";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Button from "@mui/material/Button";
import LoadingButton from "@mui/lab/LoadingButton";
import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import validator from "validator";
import "./Login.css";
import Alert from "../../Alert/Alert";
import AuthContext from "../../../context/AuthContext";
import { useTranslation } from "react-i18next";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";

const Login = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const authCtx = useContext(AuthContext);
  const [username, setUserName] = useState("");
  const [password, setPassword] = useState("");
  const [username_errors, setUsernameErrors] = useState("");
  const [password_errors, setPasswordErrors] = useState("");
  const [loginResp, setLoginResponse] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const handleClickShowPassword = () => setShowPassword(!showPassword);
  const handleMouseDownPassword = () => setShowPassword(!showPassword);
  const [btnLoading, setBtnLoading] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [alertType, setAlertType] = useState(false);

  const location = useLocation();
  const { from, navigateUrl } = location.state !== null && location.state !== undefined ? location.state : "/";
  console.log("from--> " + from + "-- " + navigateUrl);
  const { item, serviceItem } = location?.state || {};
  console.log("item_login--> ", item);
  const { mdaName, serviceId } = location?.state || {};

  function validateForm() {
    let formIsValid = true;
    if (!username) {
      formIsValid = false;
      setUsernameErrors(t("required alert"));
    } else if (!validator.isEmail(username)) {
      formIsValid = false;
      setUsernameErrors(t("email valid alert"));
    } else {
      setUsernameErrors("");
    }
    if (!password) {
      formIsValid = false;
      setPasswordErrors(t("required alert"));
    } else {
      setPasswordErrors("");
    }
    LoginMethod(formIsValid);
  }
  const headers = {
    needJson: 1,
  };
  function LoginMethod(formIsValid) {
    if (formIsValid) {
      setBtnLoading(true);
      axios
        .post(
          process.env.REACT_APP_HOST_URL + LOGIN_URL,
          {
            username: username,
            password: password,
            via: "web",
          },
          {
            headers: headers,
          }
        )
        .then((response) => {
          setLoginResponse(response.data.message);
          setAlertType(response.data.success);
          setShowAlert(true);
          console.log(response.data.apiToken);
          if (response.data.success) {
            localStorage.setItem(
              "apiToken",
              JSON.stringify(response.data.apiToken)
            );
            localStorage.setItem(
              "userDetails",
              JSON.stringify(response.data.userDetails)
            );
            authCtx.authLogin();
            setUserName("");
            setPassword("");
            if (from === 1) {
              navigate(navigateUrl);
            } else if (from === 2){
              navigate(navigateUrl, { state: { item: item, serviceItem: serviceItem } })
            } else if (from === 3) {
              navigate(navigateUrl, { state: { mdaName: mdaName, serviceId: serviceId } })
            } else {
              navigate('/');
            }
          }
          setBtnLoading(false);
        });
    }
  }

  // Input validation
  const validateEmail = (e) => {
    var email = e.target.value;
    setUserName(email);
    if (email !== "") {
      if (validator.isEmail(email)) {
        setUsernameErrors("");
      } else {
        setUsernameErrors(t("email valid alert"));
      }
    } else {
      setUsernameErrors(t("required alert"));
    }
  };

  const validatePassword = (e) => {
    var passwordVal = e.target.value;
    setPassword(passwordVal);
    if (passwordVal !== "") {
      setPasswordErrors("");
    } else {
      setPasswordErrors(t("required alert"));
    }
  };

  const [open, setOpen] = React.useState(false);
  const [scroll, setScroll] = React.useState("paper");

  const handleClickOpen = (scrollType) => () => {
    setOpen(true);
    setScroll(scrollType);
  };

  const handleClose = () => {
    setOpen(false);
  };

  document.body.onkeydown = function (e) {
    if (e.key === "Enter") {
      validateForm(e);
    }
  };

  useEffect(() => {
    const HandleBackClick = (event) => {
      console.log("HandleBackClick_login" )
      navigate('/');
    };

    window.addEventListener('popstate', HandleBackClick); // Add listener
    return () => {
      window.removeEventListener('popstate', HandleBackClick); // Clean up on unmount
    };
  }, []);

  return (
    <div className="outer-common-ui login-center-box">
      <Container>
        <section className="LoginUI outer-inner login-box">
          <Grid>
            <Grid item sm={12}>
              <div className="formSection">
                <Alert
                  setShow={setShowAlert}
                  show={showAlert}
                  msg={loginResp}
                  type={alertType}
                />
                <h3 className="head-h3">{t("login title")}</h3>
                <form method="post" name="userLoginForm">
                  <FormGroup className="form-group">
                    <FormLabel required>{t("email label")}</FormLabel>
                    <TextField
                      className="form-control"
                      type="text"
                      size="small"
                      value={username}
                      onChange={(e) => validateEmail(e)}
                    />
                    <div className="errorMsg">{username_errors}</div>
                  </FormGroup>
                  <FormGroup className="form-group">
                    <FormLabel required>{t("password label")}</FormLabel>
                    <a
                      className="forgot-link"
                      onClick={() => navigate("/forgot-password")}
                    >
                      {t("forgot password text")}
                    </a>
                    <TextField
                      className="form-control"
                      size="small"
                      type={showPassword ? "text" : "password"}
                      value={password}
                      onChange={(e) => validatePassword(e)}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              className="eye-icon"
                              aria-label="toggle password visibility"
                              onClick={handleClickShowPassword}
                              onMouseDown={handleMouseDownPassword}
                              edge="end"
                            >
                              {showPassword ? (
                                <Visibility />
                              ) : (
                                <VisibilityOff />
                              )}
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />
                    <div className="errorMsg">{password_errors}</div>
                  </FormGroup>
                  <FormGroup className="form-group">
                    <FormControlLabel
                      control={<Checkbox className="check-icon" />}
                      label={t("Keep me signed in")}
                    />
                  </FormGroup>
                  <div className="form-group">
                    <LoadingButton
                      className="btn btn-primary"
                      onClick={validateForm}
                      loading={btnLoading}
                      loadingIndicator="Loading…"
                    >
                      <span>{t("login text")}</span>
                    </LoadingButton>
                    {/* <div className="devider">
                      <span>{t("or text")}</span>
                      <hr />
                    </div>
                    <Button
                      className="btn btn-secondary"
                      onClick={handleClickOpen("paper")}
                    >
                      {t("login mobile text")}
                    </Button> */}
                  </div>
                  <p className="reg-text">
                    {t("have account text")}{" "}
                    <span onClick={() => navigate("/register")}>
                      {t("register here text")}
                    </span>
                  </p>
                </form>
              </div>
            </Grid>
          </Grid>
        </section>
      </Container>
      <Dialog
        open={open}
        onClose={handleClose}
        scroll={scroll}
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
      >
        <DialogTitle id="scroll-dialog-title">
          {t("login mobile text")}
        </DialogTitle>
        <DialogContent dividers={scroll === "paper"}>
          <DialogContentText id="scroll-dialog-description" tabIndex={-1}>
            Comming soon...
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default Login;
