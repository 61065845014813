import React, { useRef, forwardRef, useImperativeHandle, useState,useEffect  } from 'react';
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
import JsBarcode from 'jsbarcode';
import QRCode from 'qrcode';
import { useTranslation } from 'react-i18next';
import numberToWords from 'number-to-words';
import './Pdf.css';
import logo from "../../assets/Img/logo.png";

const PDFGenerator = forwardRef(({ data, paymenttype }, ref) => {
    const hiddenRef = useRef();
    const barcodeRef = useRef();
    const qrCodeRef = useRef(null);
    const [loading, setLoading] = useState(false);   
    const [imageLoaded, setImageLoaded] = useState(false); 
    const { t } = useTranslation();


    console.log("data", data);
    useImperativeHandle(ref, () => ({
        generatePdf: async () => {
            if (!imageLoaded) {
                alert('Image is still loading. Please wait.');
                return;
            }
            const element = hiddenRef.current;
            if (!element) {
                console.error('No element found to capture');
                alert('No element found to capture');
                return;
            }
            
            try {
                setLoading(true);

                // Temporarily make the element visible
                element.style.display = 'block';

                // Capture the element
                const canvas = await html2canvas(element, { scale: 2 });
                const imgData = canvas.toDataURL('image/png');

                // Hide the element again
                element.style.display = 'none';

                if (!imgData.startsWith('data:image/png')) {
                    throw new Error('Invalid image data format');
                }

                const pdf = new jsPDF('p', 'mm', 'a4');
                const pdfWidth = pdf.internal.pageSize.getWidth();
                const pdfHeight = (canvas.height * pdfWidth) / canvas.width;

                pdf.addImage(imgData, 'PNG', 0, 0, pdfWidth, pdfHeight);

                // Generate the PDF and convert it to a Blob
                const pdfBlob = pdf.output('blob');
                const pdfUrl = URL.createObjectURL(pdfBlob);

                // Open the PDF in a new tab
                window.open(pdfUrl);

            } catch (error) {
                console.error('Error generating PDF:', error);
                alert('There was an error generating the PDF. Please check the console for more details.');
            } finally {
                setLoading(false);
            }
        }
    }));
    useEffect(() => {
        if (data) {
            const prnNo = paymenttype.id === "1" ? data.data.prn : data.prn_no;
            QRCode.toCanvas(qrCodeRef.current, prnNo, {
                width: 150,
                margin: 2
            }, (error) => {
                if (error) console.error(error);
                console.log('QR code generated successfully');
            });
        }
    }, [data, paymenttype]);
    const handleImageLoad = () => {
        setImageLoaded(true);
    };
    const convertNumberToWords = (amountString) => {
        if (typeof amountString !== 'string') return 'Invalid input';
        const amount = amountString.replace('MWK', '').trim();
        if (isNaN(amount)) return 'Invalid amount';
        const number = parseFloat(amount);
        const integerPart = Math.floor(number);
        const decimalPart = Math.round((number - integerPart) * 100);
        const integerPartInWords = numberToWords.toWords(integerPart);
        const decimalPartInWords = decimalPart > 0 ? `and ${numberToWords.toWords(decimalPart)} paise` : '';
        return `${integerPartInWords} rupees ${decimalPartInWords}`;
    };

    const formatDate = (dateString) => {
        const date = new Date(dateString);
        return new Intl.DateTimeFormat('en-GB').format(date); // Formats date to dd/mm/yyyy
    };

    const formatDate1 = (dateString) => {
        const date = new Date(dateString);
        const day = String(date.getDate()).padStart(2, '0');
        const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are 0-indexed
        const year = date.getFullYear();

        return `${day}/${month}/${year}`;
    };


    return (
        <div>
            {loading && <div className="pdfloader">Loading...</div>}
            {paymenttype.id === "2"
                ? <div ref={hiddenRef} style={{display:'none'}}>
                    <div className='pdfUI' style={{ padding: '20px', backgroundColor: 'white' }}>
                        <table className="heading-table" cellpadding="" style={{ width: "100%" }}>
                            <tr>
                                <td style={{ border: 'none' }} colspan="2" >
                                    <div className="d-flex-print">
                                        <div className='logo_wd' style={{  }}>
                                        <img 
                                            className="heads-logo" 
                                            src={logo}
                                            alt="logo" 
                                            onLoad={handleImageLoad}
                                            style={{ display: imageLoaded ? 'block' : 'none' }}
                                        />
                                        </div>
                                        <div className="center">
                                            <h2 className="center" style={{ margin: '0 auto !important', marginTop: '15px !important', fontSize: '14px', backgroundColor: '#ccc', padding: '5px 25PX', width: 'fit-content !important' }}>{t('payment registration slip')}</h2>
                                        </div>
                                        <div style={{textAlign:'right'}}>
                                            <p className="unified_addr" style={{ fontSize: '12px', float: 'right', margin: '4px' }}>
                                                <span style={{ fontWeight: '600'}}>Accountant General Department</span>
                                                <span>P.O Box 30140</span>
                                                <span style={{ fontWeight: '600'}}>Lilongwe</span>                                                
                                            </p>
                                        </div>
                                    </div>
                                </td>
                            </tr>
                            
                                                     
                        </table>
                        <div className="flex-pdf">
                                        <div className="gr_box">
                                        <p style={{ padding: '3px', fontSize: '12px', fontWeight: '700' }}>{data ? data.citizen_username : ''}</p>
                                        <p style={{ padding: '3px', fontSize: '12px', margin: '0px !important' }}>{data ? data.citizen_email : ''}</p>
                                        <p style={{ padding: '3px', fontSize: '12px', margin: '0px !important' }}>Mobile: {data ? data.mobile_no : ''}</p>
                                        {data && (data.is_foreigner === "1" || data.is_foreigner === 1)
                                            ? <p style={{ padding: '3px', fontSize: '12px', margin: '0px !important' }}>National ID: {data ? data.citizen_payerId : ''}</p>
                                            : <p style={{ padding: '3px', fontSize: '12px', margin: '0px !important' }}>Passport no: {data ? data.citizen_payerId : ''}</p>}

                                        </div>
                                        <div className="gr_box d_flex">
                                            <div className='addred_bx'>
                                                <p style={{ padding: '3px', fontSize: '12.5px', margin: '3px' }}><b style={{ fontWeight: '500' }}> Notice Date :</b>
                                                <span style={{ display: 'block', fontSize: '12px', fontWeight: '700' }}>{data ? formatDate1(data.payment_generated_on) : ''}</span>
                                                </p>
                                                <p style={{ padding: '3px', fontSize: '12.5px', margin: '3px' }}><b style={{fontWeight:'500'}}>Payment Registration Number</b><br />
                                                    <span style={{ display: 'block', fontSize:'14px',fontWeight:'700' }}>{data ? data.prn_no : ''}</span>
                                                </p>
                                           </div>
                                           <div className='qr_box'>
                                                <canvas ref={qrCodeRef}></canvas>	
                                            </div> 
                                        </div>
                                       
                                    </div>
                        {/* <table style={{ width: "100%", border: "none" }} cellpadding="0" cellspacing="0">
                            <tr>
                                <td className="green-head" style={{ border: "none", textAlign: 'center', fontSize: "19px", fontWeight: 600, color: "#000" }}>
                                    <h1 className="center" style={{ fontSize: '15px' }}>{t('payment registration details')}</h1>
                                </td>
                            </tr>
                            <tbody>
                                <tr>
                                    <td style={{ border: "none" }}></td>
                                </tr>
                            </tbody>
                        </table> */}
                        <h1 className="center" style={{ fontSize: '15px' }}>{t('payment registration details')}</h1>
                        <table className="full-width amtTable" style={{ marginTop: "0px", textAlign: 'left', lineHeight: '25px', borderCollapse: 'collapse', width: '100%' }}>
                            <tbody>
                                <tr style={{ pageBreakInside: "avoid" }}>
                                    <th style={{ fontSize: '13px', fontWeight: '700', paddingTop: '5px', border: '1px solid', borderRight: '0px' }}>{t("service label")}</th>
                                    <td style={{ fontSize: '12px', fontWeight: '400', padding: '5px', border: '1px solid', borderLeft: '0px', paddingLeft:'10px' }} colspan="3"> {data ? data.service_name : ""}</td>
                                </tr>
                                <tr style={{ pageBreakInside: "avoid", border: '1px solid' }}>
                                    <th style={{ fontSize: '13px', fontWeight: '700', paddingTop: '5px', borderBottom: '1px solid' }}>{t("Amount in MWK")}:</th>
                                    <td style={{ fontSize: '12px', fontWeight: '400', paddingTop: '5px', borderBottom: '1px solid' }}>{data ? data.amount : ''}</td>
                                    <th style={{ fontSize: '13px', fontWeight: '700', paddingTop: '5px', borderBottom: '1px solid' }}>{t("Amount in words")}:</th>
                                    <td style={{ fontSize: '12px', fontWeight: '400', paddingTop: '5px', borderBottom: '1px solid' }}>{data ? (data.amount != null && data.amount !== "" ? convertNumberToWords(data.amount) : '') : ''}</td>
                                </tr>
                                <tr style={{ pageBreakInside: "avoid", border: '1px solid' }}>
                                    <th style={{ fontSize: '13px', fontWeight: '700', paddingTop: '5px', borderBottom: '1px solid' }}>{t("identity proof type")}:</th>
                                    <td style={{ fontSize: '12px', fontWeight: '400', paddingTop: '5px', borderBottom: '1px solid' }}>{data && (data.is_foreigner === "1" || data.is_foreigner === 1) ? "National ID" : "Passport Number"}</td>
                                    <th style={{ fontSize: '13px', fontWeight: '700', paddingTop: '5px', borderBottom: '1px solid' }}>{t("identity proof no")}:</th>
                                    <td style={{ fontSize: '12px', fontWeight: '400', paddingTop: '5px', borderBottom: '1px solid' }}>{data ? data.citizen_payerId : ''}</td>
                                </tr>
                                <tr style={{ pageBreakInside: "avoid", border: '1px solid' }}>
                                    <th style={{ fontSize: '13px', fontWeight: '700', paddingTop: '5px', borderBottom: '1px solid' }}>{t("bank account no")}:</th>
                                    <td style={{ fontSize: '12px', fontWeight: '400', paddingTop: '5px', borderBottom: '1px solid' }}>{data.account_number || ''}</td>
                                    <th style={{ fontSize: '13px', fontWeight: '700', paddingTop: '5px', borderBottom: '1px solid' }}>{t("bank name")}</th>
                                    <td style={{ fontSize: '12px', fontWeight: '400', paddingTop: '5px', borderBottom: '1px solid' }}>{data.bank_name || ''}</td>
                                </tr>
                                <tr style={{ pageBreakInside: "avoid", border: '1px solid' }}>
                                    <th style={{ fontSize: '13px', fontWeight: '700', paddingTop: '5px' }}>{t("branch")}:</th>
                                    <td style={{ fontSize: '12px', fontWeight: '400', paddingTop: '5px' }}>{data.branch_name || ''}</td>
                                    <th style={{ fontSize: '13px', fontWeight: '700', paddingTop: '5px' }}>{t("Slip expiry date")}:</th>
                                    <td style={{ fontSize: '12px', fontWeight: '400', paddingTop: '5px' }}><b>{data && data.expired_date !== "" && data.expired_date !== null ? formatDate(data.expired_date) : ''}</b></td>
                                </tr>
                            </tbody>
                        </table>
                        <table style={{ width: "100%", border: "none" }} cellpadding="0" cellspacing="0">
                            <tbody>
                                <tr>
                                    <td style={{ border: "none" }}></td>
                                </tr>
                            </tbody>
                        </table>
                        <div className="half-full-table" style={{ display: 'flex', marginTop: '20px', gap: '10px', flexDirection: 'row' }}>
                            <div className="half-width" style={{ width: '49%', display: 'inline-block' }}>
                                <table style={{ width: '100%', borderCollapse: 'collapse', border: '1px solid', marginBottom: '20px' }}>
                                    <thead>
                                        <tr>
                                            <th colspan="3" style={{ textAlign: 'center', fontSize: '13px', borderBottom: '1px solid', paddingTop: '10px', paddingBottom: '10px', backgroundColor: '#eff0f4' }}>CHEQUES ONLY</th>
                                        </tr>
                                        <tr>
                                            <th style={{ fontSize: '13px', borderBottom: '1px solid', paddingTop: '10px', paddingBottom: '10px', borderRight: '1px solid' }}>Bank</th>
                                            <th style={{ fontSize: '13px', borderBottom: '1px solid', paddingTop: '10px', paddingBottom: '10px', borderRight: '1px solid' }}>Cheque No</th>
                                            <th style={{ fontSize: '13px', borderBottom: '1px solid', paddingTop: '10px', paddingBottom: '10px' }}>Amount (MWK)</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td style={{ fontSize: '12px', borderBottom: '1px solid', paddingTop: '18px', paddingBottom: '18px', borderRight: '1px solid' }}></td>
                                            <td style={{ fontSize: '12px', borderBottom: '1px solid', paddingTop: '18px', paddingBottom: '18px', borderRight: '1px solid' }}></td>
                                            <td style={{ fontSize: '12px', borderBottom: '1px solid', paddingTop: '18px', paddingBottom: '18px' }}></td>
                                        </tr>
                                    </tbody>
                                </table>
                                <div className="amount-words" style={{ textAlign: 'left' }}>

                                    <div style={{ display: 'flex' }}>
                                        <strong style={{ fontSize: '13px' }}>Amount in words:</strong><span style={{ borderBottom: '1px solid', width: '71%', display: 'block', float: 'right' }}></span>
                                    </div>
                                    <div style={{ borderBottom: '1px solid', paddingBottom: '18px', paddingTop: '15px' }}></div>
                                    <div style={{ borderBottom: '1px solid', paddingBottom: '18px', paddingTop: '15px' }}></div>
                                </div>
                                <table style={{ marginTop: '50px', border: '1px solid' }}>
                                    <tbody>
                                        <tr style={{ pageBreakInside: 'avoid' }}>
                                            <td style={{ color: '#333', fontSize: '13px', fontWeight: '700', width: '50%', padding: '35px', textAlign: 'center' }}>AUTHRISED STAMP AND ENDORSEMENT </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>

                            <div className="half-width" style={{ width: '49%', display: 'inline-block' }}>
                                <table className="currency" style={{ width: '100%', borderCollapse: 'collapse', border: '1px solid' }}>
                                    <thead>
                                        <tr>
                                            <th colspan="2" style={{ textAlign: 'center', borderBottom: '1px solid', fontSize: '13px', fontWeight: '700', paddingTop: '10px', paddingBottom: '10px', backgroundColor: '#eff0f4' }}>CASH ONLY</th>
                                        </tr>
                                        <tr>
                                            <th style={{ borderBottom: '1px solid', fontSize: '14px', fontWeight: '700', paddingTop: '10px', paddingBottom: '10px' }}>Currency</th>
                                            <th style={{ borderBottom: '1px solid', fontSize: '14px', fontWeight: '700', paddingTop: '10px', paddingBottom: '10px' }}>Amount</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td style={{ fontSize: '12px', borderBottom: '1px solid', paddingTop: '3px', paddingBottom: '3px' }}>5,000/=</td>
                                            <td style={{ fontSize: '12px', borderBottom: '1px solid', paddingTop: '3px', paddingBottom: '3px' }}></td>
                                        </tr>
                                        <tr>
                                            <td style={{ fontSize: '12px', borderBottom: '1px solid', paddingTop: '3px', paddingBottom: '3px' }}>2,000/=</td>
                                            <td style={{ fontSize: '12px', borderBottom: '1px solid', paddingTop: '3px', paddingBottom: '3px' }}></td>
                                        </tr>
                                        <tr>
                                            <td style={{ fontSize: '12px', borderBottom: '1px solid', paddingTop: '3px', paddingBottom: '3px' }}>1,000/=</td>
                                            <td style={{ fontSize: '12px', borderBottom: '1px solid', paddingTop: '3px', paddingBottom: '3px' }}></td>
                                        </tr>
                                        <tr>
                                            <td style={{ fontSize: '12px', borderBottom: '1px solid', paddingTop: '3px', paddingBottom: '3px' }}>500/=</td>
                                            <td style={{ fontSize: '12px', borderBottom: '1px solid', paddingTop: '3px', paddingBottom: '3px' }}></td>
                                        </tr>
                                        <tr>
                                            <td style={{ fontSize: '12px', borderBottom: '1px solid', paddingTop: '3px', paddingBottom: '3px' }}>200/=</td>
                                            <td style={{ fontSize: '12px', borderBottom: '1px solid', paddingTop: '3px', paddingBottom: '3px' }}></td>
                                        </tr>
                                        <tr>
                                            <td style={{ fontSize: '12px', borderBottom: '1px solid', paddingTop: '3px', paddingBottom: '3px' }}>100/=</td>
                                            <td style={{ fontSize: '12px', borderBottom: '1px solid', paddingTop: '3px', paddingBottom: '3px' }}></td>
                                        </tr>
                                        <tr>
                                            <td style={{ fontSize: '12px', borderBottom: '1px solid', paddingTop: '3px', paddingBottom: '3px' }}>50/=</td>
                                            <td style={{ fontSize: '12px', borderBottom: '1px solid', paddingTop: '3px', paddingBottom: '3px' }}></td>
                                        </tr>
                                        <tr>
                                            <td style={{ fontSize: '12px', borderBottom: '1px solid', paddingTop: '3px', paddingBottom: '3px' }}>20/=</td>
                                            <td style={{ fontSize: '12px', borderBottom: '1px solid', paddingTop: '3px', paddingBottom: '3px' }}></td>
                                        </tr>
                                        <tr>
                                            <td style={{ fontSize: '12px', borderBottom: '1px solid', paddingTop: '3px', paddingBottom: '3px' }}>10/=</td>
                                            <td style={{ fontSize: '12px', borderBottom: '1px solid', paddingTop: '3px', paddingBottom: '3px' }}></td>
                                        </tr>
                                        <tr>
                                            <td style={{ fontSize: '12px', borderBottom: '1px solid', paddingTop: '3px', paddingBottom: '3px' }}>5/=</td>
                                            <td style={{ fontSize: '12px', borderBottom: '1px solid', paddingTop: '3px', paddingBottom: '3px' }}></td>
                                        </tr>
                                        <tr>
                                            <td style={{ fontSize: '12px', borderBottom: '1px solid', paddingTop: '3px', paddingBottom: '3px' }}>1/=</td>
                                            <td style={{ fontSize: '12px', borderBottom: '1px solid', paddingTop: '3px', paddingBottom: '3px' }}></td>
                                        </tr>
                                        <tr>
                                            <td style={{ fontSize: '12px', borderBottom: '1px solid', paddingTop: '3px', paddingBottom: '3px' }}>Total=</td>
                                            <td style={{ fontSize: '12px', borderBottom: '1px solid', paddingTop: '3px', paddingBottom: '3px' }}></td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <table style={{ width: '100%', borderCollapse: 'collapse', border: '1px solid', marginTop: '20px', textAlign: 'left' }}>
                            <tbody>
                                <tr style={{ pageBreakInside: "avoid" }}>
                                    <td style={{ color: "#000", fontSize: "13px", width: "50%", borderBottom: '1px solid', borderRight: '1px solid', padding: '10px' }}><b style={{ fontWeight: '600' }}>Paid in by</b><span style={{ color: "", paddingLeft: "15px", fontSize: "13px" }}></span></td>
                                    <td style={{ color: "#000", fontSize: "13px", width: "50%", borderBottom: '1px solid', borderRight: '1px solid', padding: '10px' }}><b style={{ fontWeight: '600' }}>Contact Number</b> <span style={{ color: "", paddingLeft: "15px", fontSize: "13px" }}></span></td>
                                </tr>
                                <tr style={{ pageBreakInside: "avoid" }}>
                                    <td style={{ color: "#000", fontSize: "13px", width: "50%", borderBottom: '1px solid', borderRight: '1px solid', padding: '10px' }}><b style={{ fontWeight: '600' }}>Signature</b> <span style={{ color: "", paddingLeft: "15px", fontSize: "13px" }}></span></td>
                                    <td style={{ color: "#000", fontSize: "13px", width: "50%", borderBottom: '1px solid', borderRight: '1px solid', padding: '10px' }}><b style={{ fontWeight: '600' }}>Designation</b> <span style={{ color: "", paddingLeft: "15px", fontSize: "13px" }}></span></td>
                                </tr>
                            </tbody>
                        </table>
                        <table style={{ width: "100%", border: "none" }} cellpadding="0" cellspacing="0">
                            <tbody>
                                <tr>
                                    <td style={{ border: "none" }}></td>
                                </tr>
                            </tbody>
                        </table>
                        <div style={{ color: "#000", marginTop: "20px", fontSize: "12px", textAlign: 'left' }}>
                            Kindly visit the bank with the generated payment slip and proceed with the payment there to get the receipt.
                        </div>
                        <div style={{ textAlign: 'center', color: "#888d8d", marginTop: "12px", fontSize: "11px", letterSpacing: "0.5px" }}></div>
                    </div></div>
                : (paymenttype.id === "4"
                    ? <div ref={hiddenRef} className="offlineSlip" style={{ padding: '20px', display: 'none' }}>
                        <table className="heading-table" cellpadding="" style={{ width: "100%" }}>
                            <tr>
                                <td style={{ border: 'none' }} colspan="2" >
                                    <div className="d-flex-print">
                                        <div className='logo_wd' style={{}}>
                                            <img 
                                                className="heads-logo" 
                                                src={logo}
                                                alt="logo" 
                                                onLoad={handleImageLoad}
                                                style={{ display: imageLoaded ? 'block' : 'none' }}
                                            />
                                        </div>
                                        <div className="center">
                                            <h2 className="center" style={{ margin: '0 auto !important', marginTop: '15px !important', fontSize: '14px', backgroundColor: '#ccc', padding: '5px 25PX', width: 'fit-content !important' }}>{t('payment registration slip')}</h2>
                                        </div>
                                        <div style={{ textAlign: 'right' }}>
                                            <p className="unified_addr" style={{ fontSize: '12px', float: 'right', margin: '4px' }}>
                                                <span style={{ fontWeight: '600'}}>Accountant General Department</span>
                                                <span>P.O Box 30140</span>
                                                <span style={{ fontWeight: '600'}}>Lilongwe</span>                                                
                                            </p>
                                        </div>
                                    </div>
                                </td>
                            </tr>                            
                        </table>
                        <div className="flex-pdf">
                                <div className="gr_box">
                                    <p style={{ padding: '3px', fontSize: '12px', fontWeight: '700' }}>{data ? data.citizen_username : ''}</p>
                                    <p style={{ padding: '3px', fontSize: '12px', margin: '0px !important' }}>{data ? data.citizen_email : ''}</p>
                                    <p style={{ padding: '3px', fontSize: '12px', margin: '0px !important' }}>Mobile: {data ? data.mobile_no : ''}</p>
                                    {data && (data.is_foreigner === "1" || data.is_foreigner === 1)
                                        ? <p style={{ padding: '3px', fontSize: '12px', margin: '0px !important' }}>National ID: {data ? data.citizen_payerId : ''}</p>
                                        : <p style={{ padding: '3px', fontSize: '12px', margin: '0px !important' }}>Passport no: {data ? data.citizen_payerId : ''}</p>}
                                </div>
                                <div className="gr_box d_flex">
                                    <div className='addred_bx'>
                                        <p style={{ padding: '3px', fontSize: '12.5px', margin: '3px' }}><b style={{ fontWeight: '500' }}> Notice Date :</b>
                                            <span style={{ display: 'block', fontSize: '12px', fontWeight: '700' }}>{data ? formatDate1(data.payment_generated_on) : ''}</span>
                                        </p>
                                        <p style={{ padding: '3px', fontSize: '12.5px', margin: '3px' }}><b style={{ fontWeight: '500' }}>Payment Registration Number</b><br />
                                            <span style={{ display: 'block', fontSize: '14px', fontWeight: '700' }}>{data ? data.prn_no : ''}</span>
                                        </p>
                                    </div>
                                    <div className='qr_box'>
                                        <canvas ref={qrCodeRef}></canvas>	
                                    </div> 
                                </div>                                       
                        </div>
                        <table style={{ width: "100%", border: "none" }} cellpadding="0" cellspacing="0">
                            <tr>
                                <td className="green-head" style={{ border: "none", textAlign: 'center', fontSize: "19px", fontWeight: 600, color: "#000" }}>
                                    <h1 className="center" style={{ fontSize: '15px' }}>{t('payment registration details')}</h1>
                                </td>
                            </tr>
                            <tbody>
                                <tr>
                                    <td style={{ border: "none" }}></td>
                                </tr>
                            </tbody>
                        </table>

                        <table className="full-width amtTable" style={{ marginTop: "0px", textAlign: 'left', lineHeight: '25px', borderCollapse: 'collapse', width: '100%' }}>
                            <tbody>
                                <tr style={{ pageBreakInside: "avoid" }}>
                                    <th style={{ fontSize: '13px', fontWeight: '700', paddingTop: '5px', border: '1px solid', borderRight: '0px' }}>{t("service label")}</th>
                                    <td style={{ fontSize: '12px', fontWeight: '400', padding: '5px', border: '1px solid', borderLeft: '0px' }} colspan="3"> {data ? data.service_name : ""}</td>
                                </tr>
                                <tr style={{ pageBreakInside: "avoid", border: '1px solid' }}>
                                    <th style={{ fontSize: '13px', fontWeight: '700', paddingTop: '5px', borderBottom: '1px solid', width: '25%' }}>{t("Amount in MWK")}:</th>
                                    <td style={{ fontSize: '12px', fontWeight: '400', paddingTop: '5px', borderBottom: '1px solid', borderRight: '1px solid', width: '25%' }}>{data ? data.amount : ''}</td>
                                    <th style={{ fontSize: '13px', fontWeight: '700', paddingTop: '5px', borderBottom: '1px solid', width: '25%' }}>{t("Amount in words")}:</th>
                                    <td style={{ fontSize: '12px', fontWeight: '400', paddingTop: '5px', borderBottom: '1px solid', width: '25%' }}>{data ? convertNumberToWords(data.amount) : ''}</td>
                                </tr>
                                <tr style={{ pageBreakInside: "avoid", border: '1px solid' }}>
                                    <th style={{ fontSize: '13px', fontWeight: '700', paddingTop: '5px', borderBottom: '1px solid', width: '25%' }}>{t("identity proof type")}:</th>
                                    <td style={{ fontSize: '12px', fontWeight: '400', paddingTop: '5px', borderBottom: '1px solid', borderRight: '1px solid', width: '25%' }}>{data && (data.is_foreigner === "1" || data.is_foreigner === 1) ? "National ID" : "Passport Number"}</td>
                                    <th style={{ fontSize: '13px', fontWeight: '700', paddingTop: '5px', borderBottom: '1px solid', width: '25%' }}>{t("identity proof no")}:</th>
                                    <td style={{ fontSize: '12px', fontWeight: '400', paddingTop: '5px', borderBottom: '1px solid', width: '25%' }}>{data ? data.citizen_payerId : ''}</td>
                                </tr>
                                <tr style={{ pageBreakInside: "avoid", border: '1px solid' }}>
                                    <th style={{ fontSize: '13px', fontWeight: '700', paddingTop: '5px', borderBottom: '1px solid', width: '25%' }}>{t("Slip expiry date")}:</th>
                                    <td style={{ fontSize: '12px', fontWeight: '400', paddingTop: '5px', borderBottom: '1px solid', borderRight: '1px solid', width: '25%' }}>{data && data.expired_date !== "" && data.expired_date !== null ? formatDate(data.expired_date) : ''}</td>
                                    <th style={{ fontSize: '13px', fontWeight: '700', paddingTop: '5px', borderBottom: '1px solid', width: '25%' }}>MDA:</th>
                                    <td style={{ fontSize: '12px', fontWeight: '400', paddingTop: '5px', borderBottom: '1px solid', width: '25%' }}>{data ? data.mda_name : ''}</td>
                                </tr>
                            </tbody>
                        </table>
                        <table className="full-width" style={{ width: "100%", border: "none" }} cellpadding="0" cellspacing="0">
                            <tbody>
                                <tr>
                                    <td style={{ border: "none" }}></td>
                                </tr>
                            </tbody>
                        </table>
                        <table className="full-width" style={{ width: '100%', borderCollapse: 'collapse', border: '1px solid', marginTop: '20px', textAlign: 'left' }}>
                            <tbody>
                                <tr style={{ pageBreakInside: "avoid" }}>
                                    <td style={{ color: "#000", fontSize: "13px", width: "50%", borderBottom: '1px solid', borderRight: '1px solid', padding: '10px' }}><b style={{ fontWeight: '600' }}>Paid in by</b><span style={{ color: "", paddingLeft: "15px", fontSize: "13px" }}></span></td>
                                    <td style={{ color: "#000", fontSize: "13px", width: "50%", borderBottom: '1px solid', borderRight: '1px solid', padding: '10px' }}><b style={{ fontWeight: '600' }}>Contact Number</b> <span style={{ color: "", paddingLeft: "15px", fontSize: "13px" }}></span></td>
                                </tr>
                                <tr style={{ pageBreakInside: "avoid" }}>
                                    <td style={{ color: "#000", fontSize: "13px", width: "50%", borderBottom: '1px solid', borderRight: '1px solid', padding: '10px' }}><b style={{ fontWeight: '600' }}>Signature</b> <span style={{ color: "", paddingLeft: "15px", fontSize: "13px" }}></span></td>
                                    <td style={{ color: "#000", fontSize: "13px", width: "50%", borderBottom: '1px solid', borderRight: '1px solid', padding: '10px' }}><b style={{ fontWeight: '600' }}>Designation</b> <span style={{ color: "", paddingLeft: "15px", fontSize: "13px" }}></span></td>
                                </tr>
                            </tbody>
                        </table>
                        <table className="full-width" style={{ width: "100%", border: "none" }} cellpadding="0" cellspacing="0">
                            <tbody>
                                <tr>
                                    <td style={{ border: "none" }}></td>
                                </tr>
                            </tbody>
                        </table>
                        <div style={{ color: "#000", marginTop: "20px", fontSize: "12px", textAlign: 'left' }}>
                            This notice has been issued for and on behalf of the Unified Billing System, Government of Malawi
                        </div>
                        <div style={{ textAlign: 'center', color: "#888d8d", marginTop: "12px", fontSize: "11px", letterSpacing: "0.5px" }}></div>
                    </div>
                    : <div ref={hiddenRef} className="offlineSlip" style={{ padding: '20px', display: 'none' }}>
                        <table className="heading-table" cellpadding="" style={{ width: "100%" }}>
                            <tr>
                                <td style={{ border: 'none' }} colspan="2" >
                                    <div className="d-flex-print">
                                        <div className='logo_wd' style={{  }}>
                                            <img 
                                                className="heads-logo" 
                                                src={logo}
                                                alt="logo" 
                                                onLoad={handleImageLoad}
                                                style={{ display: imageLoaded ? 'block' : 'none' }}
                                            />
                                        </div>
                                        <div className="center">
                                            <h2 className="center" style={{ margin: '0 auto !important', marginTop: '15px !important', fontSize: '14px', backgroundColor: '#ccc', padding: '5px 25PX', width: 'fit-content !important' }}>{t('payment receipt head')}</h2>
                                        </div>
                                        <div style={{ textAlign: 'right' }}>
                                            <p className="unified_addr" style={{ fontSize: '12px', float: 'right', margin: '4px' }}>
                                                <span style={{ fontWeight: '600'}}>Accountant General Department</span>
                                                <span>P.O Box 30140</span>
                                                <span style={{ fontWeight: '600'}}>Lilongwe</span>                                                
                                            </p>
                                        </div>
                                    </div>
                                </td>
                            </tr>
                           
                        </table>
                        <div className="flex-pdf">
                                <div className="gr_box">
                                <p style={{ padding: '3px', fontSize: '12px', fontWeight: '700' }}>{data ? data.citizen_username : ''}</p>
                                    <p style={{ padding: '3px', fontSize: '12px', margin: '0px !important' }}>{data ? data.citizen_email : ''}</p>
                                    <p style={{ padding: '3px', fontSize: '12px', margin: '0px !important' }}>Mobile: {data ? data.mobile_no : ''}</p>
                                    {data && (data.is_foreigner === "1" || data.is_foreigner === 1)
                                        ? <p style={{ padding: '3px', fontSize: '12px', margin: '0px !important' }}>National ID: {data ? data.citizen_payerId : ''}</p>
                                        : <p style={{ padding: '3px', fontSize: '12px', margin: '0px !important' }}>Passport no: {data ? data.citizen_payerId : ''}</p>}

                                </div>
                                <div className="gr_box d_flex">
                                    <div className='addred_bx'>
                                        <p style={{ padding: '3px', fontSize: '12.5px', margin: '3px' }}><b style={{ fontWeight: '500' }}> Notice Date :</b>
                                            <span style={{ display: 'block', fontSize: '12px', fontWeight: '700' }}>{data ? formatDate1(data.data.payment_generated_on) : ''}</span>
                                        </p>
                                        <p style={{ padding: '3px', fontSize: '12.5px', margin: '3px' }}><b style={{fontWeight:'500'}}>Payment Registration Number</b><br />
                                            <span style={{ display: 'block', fontSize:'14px',fontWeight:'700' }}>{data ? (paymenttype.id === "1" ? data.data.prn : data.prn_no) : ''}</span>
                                        </p>
                                    </div>
                                    <div className='qr_box'>
                                        <canvas ref={qrCodeRef}></canvas>	
                                    </div> 
                                </div>                                       
                        </div>
                        <table style={{ width: "100%", border: "none" }} cellpadding="0" cellspacing="0">
                            <tr>
                                <td className="green-head" style={{ border: "none", textAlign: 'center', fontSize: "19px", fontWeight: 600, color: "#000" }}>
                                    <h1 className="center" style={{ fontSize: '15px' }}>{paymenttype.id === "4" ? t('payment registration details') : t('payment receipt details')}</h1>
                                </td>
                            </tr>
                            <tbody>
                                <tr>
                                    <td style={{ border: "none" }}></td>
                                </tr>
                            </tbody>
                        </table>

                        <table className="full-width amtTable" style={{ marginTop: "0px", textAlign: 'left', lineHeight: '25px', borderCollapse: 'collapse', width: '100%' }}>
                            <tbody>
                                <tr style={{ pageBreakInside: "avoid" }}>
                                    <th style={{ fontSize: '13px', fontWeight: '700', paddingTop: '5px', border: '1px solid', borderRight: '0px' }}>{t("service label")}</th>
                                    <td style={{ fontSize: '12px', fontWeight: '400', padding: '5px', border: '1px solid', borderLeft: '0px' }} colspan="3"> {data ? (paymenttype.id === "1" ? data.data.service_name : data.service_name) : ""}</td>
                                </tr>
                                <tr style={{ pageBreakInside: "avoid", border: '1px solid' }}>
                                    <th style={{ fontSize: '13px', fontWeight: '700', paddingTop: '5px', borderBottom: '1px solid' }}>{t("Amount in MWK")}:</th>
                                    <td style={{ fontSize: '12px', fontWeight: '400', paddingTop: '5px', borderBottom: '1px solid' }}>{data ? (paymenttype.id === "1" ? data.amount : data.amount) : ''}</td>
                                </tr>
                                <tr style={{ pageBreakInside: "avoid", border: '1px solid' }}>
                                    <th style={{ fontSize: '13px', fontWeight: '700', paddingTop: '5px', borderBottom: '1px solid' }}>{t("Amount in words")}:</th>
                                    <td style={{ fontSize: '12px', fontWeight: '400', paddingTop: '5px', borderBottom: '1px solid' }}>{data ? (paymenttype.id === "1" ? convertNumberToWords(data.data.amount) : convertNumberToWords(data.amount)) : ''}</td>
                                </tr>
                                <tr style={{ pageBreakInside: "avoid", border: '1px solid' }}>
                                    <th style={{ fontSize: '13px', fontWeight: '700', paddingTop: '5px', borderBottom: '1px solid' }}>{t("payment received on")}:</th>
                                    <td style={{ fontSize: '12px', fontWeight: '400', paddingTop: '5px', borderBottom: '1px solid' }}>{data && data.end_time !== "" && data.data.end_time !== null ? formatDate(data.data.end_time) : ''}</td>
                                </tr>
                                <tr style={{ pageBreakInside: "avoid", border: '1px solid' }}>
                                    <th style={{ fontSize: '13px', fontWeight: '700', paddingTop: '5px', borderBottom: '1px solid' }}>{t("identity proof type")}:</th>
                                    <td style={{ fontSize: '12px', fontWeight: '400', paddingTop: '5px', borderBottom: '1px solid' }}>{data && (data.is_foreigner === "1" || data.is_foreigner === 1) ? "National ID" : "Passport Number"}</td>
                                </tr>
                                <tr style={{ pageBreakInside: "avoid", border: '1px solid' }}>
                                    <th style={{ fontSize: '13px', fontWeight: '700', paddingTop: '5px', borderBottom: '1px solid' }}>{t("identity proof no")}:</th>
                                    <td style={{ fontSize: '12px', fontWeight: '400', paddingTop: '5px', borderBottom: '1px solid' }}>{data ? data.citizen_payerId : ''}</td>
                                </tr>
                                <tr style={{ pageBreakInside: "avoid", border: '1px solid' }}>
                                    <th style={{ fontSize: '13px', fontWeight: '700', paddingTop: '5px', borderBottom: '1px solid' }}>{t("payment mode")}:</th>
                                    <td style={{ fontSize: '12px', fontWeight: '400', paddingTop: '5px', borderBottom: '1px solid' }}>Online Payment</td>
                                </tr>
                                {/* <tr>
                                    <th style={{fontSize:'13px', fontWeight:'700', paddingTop:'5px', borderBottom:'1px solid'}}>{t("reference number")}:</th>
                                    <td style={{fontSize:'12px', fontWeight:'400', paddingTop:'5px', borderBottom:'1px solid'}}>reference number</td>                                    
                                </tr> */}
                            </tbody>
                        </table>
                        <table style={{ width: "100%", border: "none" }} cellpadding="0" cellspacing="0">
                            <tbody>
                                <tr>
                                    <td style={{ border: "none" }}></td>
                                </tr>
                            </tbody>
                        </table>
                        <table className="full-width" style={{ width: '100%', borderCollapse: 'collapse', border: '1px solid', marginTop: '20px', textAlign: 'left' }}>
                            <tbody>
                                <tr style={{ pageBreakInside: "avoid" }}>
                                    <td style={{ color: "#000", fontSize: "13px", width: "50%", borderBottom: '1px solid', borderRight: '1px solid', padding: '10px' }}><b style={{ fontWeight: '600' }}>Paid in by</b><span style={{ color: "", paddingLeft: "15px", fontSize: "13px" }}></span></td>
                                    <td style={{ color: "#000", fontSize: "13px", width: "50%", borderBottom: '1px solid', borderRight: '1px solid', padding: '10px' }}><b style={{ fontWeight: '600' }}>Contact Number</b> <span style={{ color: "", paddingLeft: "15px", fontSize: "13px" }}></span></td>
                                </tr>
                                <tr style={{ pageBreakInside: "avoid" }}>
                                    <td style={{ color: "#000", fontSize: "13px", width: "50%", borderBottom: '1px solid', borderRight: '1px solid', padding: '10px' }}><b style={{ fontWeight: '600' }}>Signature</b> <span style={{ color: "", paddingLeft: "15px", fontSize: "13px" }}></span></td>
                                    <td style={{ color: "#000", fontSize: "13px", width: "50%", borderBottom: '1px solid', borderRight: '1px solid', padding: '10px' }}><b style={{ fontWeight: '600' }}>Designation</b> <span style={{ color: "", paddingLeft: "15px", fontSize: "13px" }}></span></td>
                                </tr>
                            </tbody>
                        </table>
                        <table style={{ width: "100%", border: "none" }} cellpadding="0" cellspacing="0">
                            <tbody>
                                <tr>
                                    <td style={{ border: "none" }}></td>
                                </tr>
                            </tbody>
                        </table>
                        <div style={{ color: "#000", marginTop: "20px", fontSize: "12px", textAlign: 'left' }}>
                            This notice has been issued for and on behalf of the Unified Billing System, Government of Malawi
                        </div>
                        <div style={{ textAlign: 'center', color: "#888d8d", marginTop: "12px", fontSize: "11px", letterSpacing: "0.5px" }}></div>
                    </div>)}
        </div>
    );
});

export default PDFGenerator;
