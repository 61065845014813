import { Container, RadioGroup, Radio, FormControlLabel, styled, Grid, FormLabel, FormGroup, MenuItem, Select } from "@mui/material";
import React, { useState, useEffect, useCallback } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import axios from "axios";
import { SERVICES_DETAIL, GET_USER_DETAILS, TRANSACTION_PAY, TRANSACTION_UPDATE, ALL_SERVICES_LIST } from "../../utils/api-constant";
import "./AllServices.css";
import LoadingPage from "../SeviceScreen/LoadingPage";
import ErrorSection from "../Error/ErrorSection";
import LoadingButton from "@mui/lab/LoadingButton";
import useRazorpay from "react-razorpay";
import PaymentSuccess from "../Makepayment/PaymentSuccess";
import PaymentFailed from "../Makepayment/PaymentFailed";
import Ministry from "../../assets/Img/all_service_ministry.png";
import Validity from "../../assets/Img/all_service_validity.png";
import Price from "../../assets/Img/all_service_price.png";
import { replaceSpaceWithDash } from "../../utils/custom";

function AllServicesDetail() {

  const { t } = useTranslation();
  const [ServiceDetail, setServiceDetail] = useState({});
  const [Loading, setLoading] = useState(true);
  const [Error, setError] = useState(null);
  const location = useLocation();
  const { mdaName, serviceId } = location.state !== null && location.state !== undefined ? location.state : "/";
  console.log("mdaName--> " + mdaName);
  const [Razorpay] = useRazorpay();
  const [MdaName, setMdaName] = useState(mdaName ?? '');
  const [PaymentSuccessAlert, setPaymentSuccessAlert] = useState({
    success: false,
    data: ""
  });
  const [PaymentFailedAlert, setPaymentFailedAlert] = useState({
    success: false,
    data: ""
  });
  const [UserDetails, setUserDetails] = useState({});
  const [SelectedPayment, setSelectedPayment] = useState({});
  const [PaymentOptionArray, setPaymentOptionArray] = useState([]);
  const [PaymentLoading, setPaymentLoading] = useState(false);
  const url1 = window.location.href;
  const hashPart = url1.split('#')[1];
  const parts = hashPart.split('/');
  const ScreenName = parts[2];
  console.log('ScreenName--> ', ScreenName);
  const [SelectedPassportType, setSelectedPassportType] = useState('');
  const [SelectedServiceType, setSelectedServiceType] = useState('');
  const [SelectedBookletType, setSelectedBookletType] = useState('');
  const navigate = useNavigate();

  const getServicesDetail = (apiToken, serviceId) => {
    const params = {
      headers: {
        needJson: 1,
        apiToken: apiToken,
        "Content-Type": "application/json",
      },
    };
    axios
      .get(process.env.REACT_APP_HOST_URL + SERVICES_DETAIL + serviceId, params)
      .then((response) => {
        if (response.data.services !== null) {
          setServiceDetail(response.data.services);
          let paymentOptions = response?.data?.services?.payment_option;
          if (typeof paymentOptions === 'string') {
            paymentOptions = [paymentOptions];
          }
          console.log("paymentOptions", paymentOptions)
          if (paymentOptions?.length > 0) {
            const PaymentOptionArray = paymentOptions.map(id => {
              let name;
              switch (id) {
                case '1':
                  name = "Pay with Payment Gateway";
                  break;
                case '2':
                  name = "Pay with Bank Payment";
                  break;
                case '4':
                  name = "Pay with Offline Payment";
                  break;
                default:
                  name = "";
              }
              return { id, name };
            });
            console.log(PaymentOptionArray);
            setPaymentOptionArray(PaymentOptionArray);
          } else {
            setPaymentOptionArray([]);
          }
        }
        setLoading(false);
      })
      .catch((e) => {
        setLoading(false);
        setError(e.message);
        console.log(e)
      });
  };

  const getAllServicesList = (apiToken) => {
    const params = {
      headers: {
        needJson: 1,
        apiToken: apiToken,
        "Content-Type": "application/json",
      },
    };
    axios
      .get(`${process.env.REACT_APP_HOST_URL + ALL_SERVICES_LIST}&start=0&limit=0`, params)
      .then((response) => {
        const ServiceName = ScreenName?.toLowerCase().replace(/-/g, " ");
        const matchingServices = response?.data?.list
          .map(item => {
            const selectedService = item.services.find(service =>
              service.serviceName.toLowerCase().replace(/-/g, " ") === ServiceName
            );
            return selectedService
              ? { mdaName: item.mdaName, service: selectedService }
              : null;
          })
          .filter(Boolean);
        setMdaName(matchingServices ? matchingServices[0]?.mdaName : '');
        console.log("matchingServices--> ", matchingServices?.length);
        if (matchingServices?.length > 0){
          console.log("matchingServices12--> ", matchingServices[0]?.service);
          if (matchingServices[0]?.service?.reference_number !== null && matchingServices[0]?.service?.reference_number !== "") {
            getServicesDetail(apiToken, matchingServices[0]?.service?.reference_number);
          } else {
            getServicesDetail(apiToken, matchingServices[0]?.service?.serviceId);
          }
        } else {
          setLoading(false);
        }
       
      })
      .catch((e) => {
        setLoading(false);
        setError(e.message);
      });
  };

  useEffect(() => {
    let apiToken = JSON.parse(localStorage.getItem("apiToken"));
    if(serviceId !== undefined && serviceId !== null){
      setMdaName(mdaName);
      getServicesDetail(apiToken, serviceId);
    } else {
      getAllServicesList(apiToken)
    }
    getUserDetails(apiToken);
  }, []);

  function getUserDetails(apiToken) {
    const params = {
      headers: {
        needJson: 1,
        apiToken: apiToken,
        "Content-Type": "application/json",
      },
    };
    axios
      .get(process.env.REACT_APP_HOST_URL + GET_USER_DETAILS, params)
      .then((response) => {
        if (response.data.success) {
          setUserDetails(response.data.userDetails);
        }
      })
      .catch((e) => {
        setError(e.message);
      });
  };

  function TransactionPay(from) {
    let apiToken = JSON.parse(localStorage.getItem("apiToken"));
    const parts = [UserDetails.fname, UserDetails.lname, UserDetails.sur_name];
    const fullName = parts.filter(Boolean).join(' ').trim();
    setPaymentLoading(true);
    axios
      .post(
        process.env.REACT_APP_HOST_URL + TRANSACTION_PAY,
        {
          payerId: UserDetails.nationality === "MALAWI" ? UserDetails.nin_number : UserDetails.passport_number,
          // foreigner: UserDetails.nationality === "MALAWI" ? "1" : "2" saratha ka asked to change,
          is_foreigner: UserDetails.nationality === "MALAWI" ? "1" : "2",
          userName: fullName,
          email: UserDetails.email,
          contactNo: UserDetails.phone,
          service_uid: ServiceDetail.uid,
          is_active: 1,
          portal: 2,
          payment_mode: from
        },
        {
          headers: {
            needJson: 1,
            apiToken: apiToken,
            "Content-Type": "application/json",
          },
        }
      )
      .then((response) => {
        console.log(response.data);
        if (response.data.success) {
          if (from === "1") {
            handlePayment(response.data.prn_no, ServiceDetail, UserDetails);
          } else {
            setPaymentLoading(false);
            setPaymentSuccessAlert({
              success: true,
              data: response.data
            });
          }
        } else if (response.data.success === false) {
          setPaymentLoading(false);
          setPaymentFailedAlert({
            success: true,
            data: response.data
          });
        } else {
          setPaymentLoading(false);
          setError("true");
        }
      }).catch((e) => {
        setPaymentLoading(false);
        setPaymentSuccessAlert({
          success: false,
          data: ""
        });
        setPaymentFailedAlert({
          success: false,
          data: ""
        });
        setError(e.message);
      });
  }

  function TransactionUpdate(status, payment_id, error_code, error_message, prn_no) {
    let apiToken = JSON.parse(localStorage.getItem("apiToken"));
    setPaymentLoading(true);
    axios
      .post(
        process.env.REACT_APP_HOST_URL + TRANSACTION_UPDATE,
        {
          prn_status: status,
          receipt_no: payment_id !== null && payment_id !== "" && payment_id !== undefined ? payment_id : "",
          bank_transaction_id: payment_id !== null && payment_id !== "" && payment_id !== undefined ? payment_id : "",
          prn: prn_no,
          error_code: error_code,
          error_message: error_message,
        },
        {
          headers: {
            needJson: 1,
            apiToken: apiToken,
            "Content-Type": "application/json",
          },
        }
      )
      .then((response) => {
        console.log(response.data);
        setPaymentLoading(false);
        if (response.data.success) {
          if (status === "Payment Received") {
            setPaymentSuccessAlert({
              success: true,
              data: response.data
            });
          } else if (status === "failed") {
            setPaymentFailedAlert({
              success: true,
              data: response.data
            });
          }
        } else {
          setError("true");
        }
      }).catch((e) => {
        setPaymentLoading(false);
        setPaymentSuccessAlert({
          success: false,
          data: ""
        });
        setPaymentFailedAlert({
          success: false,
          data: ""
        });
        setError(e.message);
      });
  }

  const handlePayment = useCallback((prn_no, SelectedIdAmount, UserDetails) => {
    setPaymentLoading(false);
    console.log(parseFloat(SelectedIdAmount.amount));
    const parts = [UserDetails.fname, UserDetails.lname, UserDetails.sur_name];
    const fullName = parts.filter(Boolean).join(' ').trim();
    console.log("UserDetails", UserDetails);
    const options = {
      key: "rzp_test_g6KEuH6FbRCm1N",
      amount: parseFloat(SelectedIdAmount.amount) * 100,
      currency: "MWK",
      name: fullName,
      description: "Transaction",
      handler: (response) => {
        console.log("Payment Success", response);
        TransactionUpdate("Payment Received", response.razorpay_payment_id, "", "", prn_no)
      },
      prefill: {
        name: fullName,
        email: UserDetails.email,
        contact: UserDetails.phone,
      },
      theme: {
        "color": "#D8071E"
      },
      modal: {
        ondismiss: function () {
          console.log("Payment popup closed");
        }
      }
    };

    const rzpay = new Razorpay(options);
    rzpay.on('payment.failed', function (response) {
      console.log("Payment Failed", response.error);
      TransactionUpdate("failed", response.payment_id, response.error.reason, response.error.description, prn_no)
      rzpay.close();
    });
    rzpay.open();
  }, [Razorpay]);

  const HandleFailedClose = () => {
    setPaymentFailedAlert({
      success: false,
      data: ""
    });
    setSelectedPayment({});
  }

  const HandleSuccessClose = () => {
    setPaymentSuccessAlert({
      success: false,
      data: ""
    });
  }

  const BpIcon = styled('span')(({ theme }) => ({
    borderRadius: '50%',
    width: 20,
    height: 20,
    boxShadow:
      theme.palette.mode === 'dark'
        ? '0 0 0 1px rgb(16 22 26 / 40%)'
        : 'inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)',
    backgroundColor: theme.palette.mode === 'dark' ? '#394b59' : '#f5f8fa',
    backgroundImage:
      theme.palette.mode === 'dark'
        ? 'linear-gradient(180deg,hsla(0,0%,100%,.05),hsla(0,0%,100%,0))'
        : 'linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))',
    '.Mui-focusVisible &': {
      outline: '2px auto rgba(19,124,189,.6)',
      outlineOffset: 2,
    },
    'input:hover ~ &': {
      backgroundColor: theme.palette.mode === 'dark' ? '#30404d' : '#ebf1f5',
    },
    'input:disabled ~ &': {
      boxShadow: 'none',
      background:
        theme.palette.mode === 'dark' ? 'rgba(57,75,89,.5)' : 'rgba(206,217,224,.5)',
    },
  }));

  const BpCheckedIcon = styled(BpIcon)({
    backgroundColor: '#137cbd',
    backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
    '&::before': {
      display: 'block',
      width: 20,
      height: 20,
      backgroundImage: 'radial-gradient(#fff,#fff 28%,transparent 32%)',
      content: '""',
    },
    'input:hover ~ &': {
      backgroundColor: '#106ba3',
    },
  });

  const HandlePaymentClick = (item) => {
    setSelectedPayment(item);
  };

  const HandleSubmitClick = () => {
    const data1 = {
      passportType: SelectedPassportType?.name ?? '',
      serviceType: SelectedServiceType?.name ?? '',
      bookletType: SelectedBookletType?.name ?? '',
      serviceDetail: ServiceDetail
    }
    console.log("data1--> ", data1)
    const screen = ScreenName.split('-');
    console.log("parts[0]--> ", screen[0])
    // navigate(`/all-services-detail/${'apply-passport'}/${'apply'}`, { state: { data: data1 } })
    if (ServiceDetail.uid === "98de7522-c0a9-391d-907c-e1d81b370e48" || ServiceDetail.uid === "718b9f88-af8b-3b87-be84-8f4880eebc6d") {
      // navigate(`/all-services-detail/${'apply-passport'}/${'apply'}`, { state: { data: data1 } })
      navigate(`/all-services-detail/${replaceSpaceWithDash(ServiceDetail.serviceName)}/${screen[0]}`, { state: { data: data1 } })
    } else {
      if (SelectedPayment.id === "1") {
        TransactionPay("1")
      } else if (SelectedPayment.id === "2") {
        TransactionPay("2")
      } else if (SelectedPayment.id === "4") {
        TransactionPay("4")
      }
    }
}

  const PassportTypeArray = [
    { id: 1, name: "Ordinary" },
    { id: 2, name: "Diplomatic" },
    { id: 3, name: "Service" },
    { id: 4, name: "Minor" }
  ]

  const ServiceTypeArray = [
    { id: 1, name: "Normal" },
    { id: 2, name: "Express" },
    { id: 3, name: "Urgent" }
  ]

  const BookletTypeArray = [
    { id: 1, name: "36 Pages" },
    { id: 2, name: "48 Pages" }
  ]

  if (PaymentSuccessAlert.success) return <PaymentSuccess data={PaymentSuccessAlert.data} handleclose={HandleSuccessClose} paymenttype={SelectedPayment} />;

  if (PaymentFailedAlert.success) return <PaymentFailed data={PaymentFailedAlert.data} handleclose={HandleFailedClose} />;

  if (Error) return <ErrorSection />;

  if (Loading) return <LoadingPage />;

  return (
    <div className="all-service detail">
      <div className="all-service-header">
        <Container>
          <div className="all-service-header-inner ">
            <div className="left-div service-detail-left-div">
              <h6 className="left-serviceall-text1">{Object.keys(ServiceDetail).length > 0 && (ServiceDetail.serviceName || '')}</h6>
              <h6 className="left-serviceall-text2">{MdaName || ''}</h6>
            </div>
          </div>
        </Container>
      </div>
      <section className="all-services-view all-service-details-view">
        <Container>
          <div className="service-page-detail">
            <div className="container">
              <h1 className="service-name">{Object.keys(ServiceDetail).length > 0 && (ServiceDetail.serviceName || '')}</h1>
              <h2 className="label">{t("about this service")}</h2>
              <p className="description">{Object.keys(ServiceDetail).length > 0 && (ServiceDetail.description || '')}
              </p>

              <div className="mbl-con">
                <div className="info-containers">
                  <Grid xs={12} md={6}>
                    <div className="icon-item">
                      <img src={Ministry} alt='' className="img-ministry" />
                      <span className="info-name-head">{t("provided by")}<span className="info-name">{MdaName || ''}</span></span>
                    </div>
                  </Grid>
                  {/* (Object.keys(ServiceDetail).length > 0 && ServiceDetail.validate_duartion !== null && ServiceDetail.validate_duartion !== "") &&
                  <Grid xs={12} md={6}>
                  <div className="icon-item">
                    <img src={Validity} alt='' className="img-ministry"  />
                    <span className="info-name-head">{t("validity")}<span className="info-name">{ServiceDetail.validate_duartion + (ServiceDetail.validate_duartion > 1 ? t("days") : t("day"))}</span></span>
                    </div>
                    </Grid> */}
                </div>
                {Object.keys(ServiceDetail).length > 0 && <div className="info-item  icon-item">
                <img src={Price} alt='' className="img-ministry min1"  />
                  <span className="info-name-head1">{t("prize")}<span className="info-name">{t("MWK") + ServiceDetail.amount || ''}</span></span>
                </div>}
              </div>
              {ServiceDetail.uid === "98de7522-c0a9-391d-907c-e1d81b370e48" || ServiceDetail.uid === "718b9f88-af8b-3b87-be84-8f4880eebc6d"  ? <div className="form-type">
                <FormGroup className="form-group flex-field-form">
                  <FormGroup className="form-group mda-group flex-field-grp" style={{paddingTop:'0px'}}>
                    <FormLabel style={{ minWidth: 50 }} required className='list-item-text'>{t('passport type')} </FormLabel>
                    <Select className="form-select-ps" value={SelectedPassportType.name} displayEmpty>
                      <MenuItem disabled>
                        <span style={{ opacity: 0.4 }}>{t("select")}</span>
                      </MenuItem>
                      {PassportTypeArray.length > 0 ? (
                        PassportTypeArray.map((item, index) =>
                          <MenuItem
                            onClick={(e) => setSelectedPassportType(item)}
                            key={item.id}
                            value={item.id}
                          >
                            {item.name}
                          </MenuItem>
                        )
                      ) : (
                        <MenuItem disabled>No data available</MenuItem>
                      )}
                    </Select>
                  </FormGroup>
                  <FormGroup className="form-group mda-group flex-field-grp">
                    <FormLabel style={{ minWidth: 50 }} required className='list-item-text'>{t('service type')} </FormLabel>
                    <Select className="form-select-ps" value={SelectedServiceType.name} displayEmpty>
                      <MenuItem disabled>
                        <span style={{ opacity: 0.4 }}>{t("select")}</span>
                      </MenuItem>
                      {ServiceTypeArray.length > 0 ? (
                        ServiceTypeArray.map((item, index) =>
                          <MenuItem
                            onClick={(e) => setSelectedServiceType(item)}
                            key={item.id}
                            value={item.id}
                          >
                            {item.name}
                          </MenuItem>
                        )
                      ) : (
                        <MenuItem disabled>No data available</MenuItem>
                      )}
                    </Select>
                  </FormGroup>
                  <FormGroup className="form-group mda-group flex-field-grp">
                    <FormLabel style={{ minWidth: 50 }} required className='list-item-text'>{t('booklet type')} </FormLabel>
                    <Select className="form-select-ps" value={SelectedBookletType.name} displayEmpty>
                      <MenuItem disabled>
                        <span style={{ opacity: 0.4 }}>{t("select")}</span>
                      </MenuItem>
                      {BookletTypeArray.length > 0 ? (
                        BookletTypeArray.map((item, index) =>
                          <MenuItem
                            onClick={(e) => setSelectedBookletType(item)}
                            key={item.id}
                            value={item.id}
                          >
                            {item.name}
                          </MenuItem>
                        )
                      ) : (
                        <MenuItem disabled>No data available</MenuItem>
                      )}
                    </Select>
                  </FormGroup>
                </FormGroup>
              </div> : null}

              {ServiceDetail.uid === "98de7522-c0a9-391d-907c-e1d81b370e48" || ServiceDetail.uid === "718b9f88-af8b-3b87-be84-8f4880eebc6d"  ? null :
              (Object.keys(ServiceDetail).length > 0 && <div className="payment-containers">
                <div className="paymentservice-option">
                  <Grid xs={12} md={6}>
                    <div className="payment-txt1">{t("Choose the payment type")}</div>
                  </Grid>
                  <Grid xs={12} md={6}>
                    <div className="paymentOption">
                      <RadioGroup defaultValue="outlined" name="radio-buttons-group">
                        {PaymentOptionArray.map((item) =>
                          item.name !== null && item.name !== ''
                            ? <FormControlLabel value={item.id} control={<Radio
                              className="Radio-btn-grp"
                              disableRipple
                              color="default"
                              checkedIcon={<BpCheckedIcon />}
                              icon={<BpIcon />} />} label={item.name}
                              onClick={(e) => HandlePaymentClick(item)}
                              sx={{
                             '& .MuiFormControlLabel-label':{
                              marginTop:'-10px',

                                }
                            }}/>
                            : null)}

                      </RadioGroup>
                    </div>
                  </Grid>
                </div>
              </div>)}
              {(Object.keys(SelectedPayment).length > 0 && SelectedPayment.id === "2") && <div className="payment-btn">
                <div className="no_wdth_mbl" style={{ minWidth: 80 }}></div>
                <div className="bank-payment">
                  <FormLabel className="bank-pay-txt">{t("bank text")}</FormLabel>
                  <div className="row-center paymentOptionDetails">
                    <FormGroup className="bank-group">
                      <FormLabel className="bank-head">{t("bank name")}</FormLabel>
                      <FormLabel className="bank-detail">{ServiceDetail.bank_name}</FormLabel>
                    </FormGroup>
                    <FormGroup className="bank-group">
                      <FormLabel className="bank-head">{t("account no")}</FormLabel>
                      <FormLabel className="bank-detail">{ServiceDetail.account_number}</FormLabel>
                    </FormGroup>
                    <FormGroup className="bank-group">
                      <FormLabel className="bank-head">{t("branch")}</FormLabel>
                      <FormLabel className="bank-detail">{ServiceDetail.branch_name}</FormLabel>
                    </FormGroup>
                  </div>
                </div>

              </div>}
              {ServiceDetail.uid === "98de7522-c0a9-391d-907c-e1d81b370e48" || ServiceDetail.uid === "718b9f88-af8b-3b87-be84-8f4880eebc6d" 
              ? (Object.keys(SelectedPassportType).length > 0 && Object.keys(SelectedServiceType).length > 0 && Object.keys(SelectedBookletType).length > 0) &&
                <div className="payment-btn-start">
                  <div className="">
                    <LoadingButton
                      loading={PaymentLoading}
                      className="btn btn-primary"
                      loadingIndicator="Loading…"
                      onClick={HandleSubmitClick}
                      variant="contained" >
                        <span>{t("proceed")}</span>
                    </LoadingButton>
                  </div>
                </div>
              : null}
              {ServiceDetail.uid === "98de7522-c0a9-391d-907c-e1d81b370e48" || ServiceDetail.uid === "718b9f88-af8b-3b87-be84-8f4880eebc6d" ? null : 
              Object.keys(SelectedPayment).length > 0 &&
                <div className="payment-btn-start">
                  <div className="">
                    <LoadingButton
                      loading={PaymentLoading}
                      className="btn btn-primary"
                      loadingIndicator="Loading…"
                      onClick={HandleSubmitClick}
                      variant="contained" >
                      <span>{SelectedPayment.id === "1" ? t("pay and proceed") : t("generate slip")}</span>
                    </LoadingButton>
                  </div>
                </div>
                }
            </div>
          </div>
        </Container>
      </section>
    </div>
  );
}

export default AllServicesDetail;