import {
  Container,
  FormGroup,
  FormLabel,
  Grid,
  InputAdornment,
  MenuItem,
  Select,
  TextField, IconButton
} from "@mui/material";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import SearchIcon from "@mui/icons-material/Search";
import useApiFetch from "../../hooks/useApiFetch";
import {
  CATEGORY_LISTALL,
  HEALTH_FACILITY_DISTRICT,
  HEALTH_FACILITY_LIST,
  HEALTH_FACILITY_TYPE,
  MDA_LISTALL,
} from "../../utils/api-constant";
import LoadingPage from "./LoadingPage";
import { replaceSpaceWithDash } from "../../utils/custom";
import { Link, useNavigate } from "react-router-dom";
import ErrorSection from "../Error/ErrorSection";
import { useState } from "react";
import axios from "axios";
import FormLoader from "../Loader/FormLoader";

const HealthFacility = () => {
  const { t } = useTranslation();
  const [districtValue, setDistrictValue] = useState("");
  const [typeValue, setTypeValue] = useState("");
  const [searchValue, setSearchValue] = useState("");
  const [loading, setLoading] = useState(false);
  const [facilityList, setFacilityList] = useState([]);
  const [listSuccess, setListSuccess] = useState('');
  const navigate = useNavigate();

  const {
    data: districtData,
    loading: districtLoading,
    error: districtError,
  } = useApiFetch(process.env.REACT_APP_HOST_URL + HEALTH_FACILITY_DISTRICT);

  const {
    data: TypeData,
    loading: TypeLoading,
    error: TypeError,
  } = useApiFetch(process.env.REACT_APP_HOST_URL + HEALTH_FACILITY_TYPE);

  const {
    data: listData,
    loading: listLoading,
    error: listError,
  } = useApiFetch(process.env.REACT_APP_HOST_URL + CATEGORY_LISTALL);

  const {
    data: mdaDtata,
    loading: mdaLoading,
    error: mdaError,
  } = useApiFetch(process.env.REACT_APP_HOST_URL + MDA_LISTALL);

  const getFilterFacility = async (districtValue, typeValue) => {
    setLoading(true);
    const token = JSON.parse(localStorage.getItem("apiToken"));
    const params = {
      headers: {
        "Content-Type": "application/json",
        needJson: 1,
        apiToken: token,
      },
    };
    await axios
      .get(
        process.env.REACT_APP_HOST_URL +
        HEALTH_FACILITY_LIST +
        `?district_id=${districtValue}&facility_type_id=${typeValue}&opensearch=${searchValue}`,
        params
      )
      .then((res) => {
        console.log(res.data);
        if (!res.data.success) {
          setFacilityList([]);
          setListSuccess(res.data.message);
        } else {
          setListSuccess('');
          if (res.data?.list?.status === "failure"){
            setFacilityList([]);
          }
          if (res.data.list.data.datas && Array.isArray(res.data.list.data.datas)) {
            setFacilityList(res.data.list.data.datas);
          } else if (res.data.list.data.datas && typeof res.data.list.data.datas === 'object') {
            setFacilityList([res.data.list.data.datas]);
          }
        }
        setLoading(false);
      })
      .catch((e) => {
        console.log(e.message);
        setLoading(false);
      });
  };

  /*useEffect(() => {
    if (!districtLoading) {
      getFilterFacility();
    }
  }, [districtValue, typeValue, districtLoading]);*/

  useEffect(() => {
    const HandleBackClick = (event) => {
      console.log("ServicePageLayout_HandleBackClick")
      navigate('/');
    };
    window.addEventListener('popstate', HandleBackClick); // Add listener
    return () => {
      window.removeEventListener('popstate', HandleBackClick); // Clean up on unmount
    };
  }, []);

  if (listError || mdaError || districtError || TypeError)
    return <ErrorSection />;
  if (listLoading || mdaLoading || districtLoading || TypeLoading)
    return <LoadingPage />;

  if (!districtData?.list || Object.keys(districtData.list).length === 0 || districtData.list?.code ||
    !TypeData?.list || Object.keys(TypeData.list).length === 0 || TypeData.list?.code)
    return <ErrorSection />;

  const currentUrl = window.location.hash.split("/");
  const cat = currentUrl[2];
  const serviceName = currentUrl[3];
  var currentService;
  if (cat === "services") {
    currentService = listData.list.find(
      (item) => replaceSpaceWithDash(item.name) === serviceName
    );
  } else if (cat === "mda") {
    currentService = mdaDtata.list.find(
      (item) => replaceSpaceWithDash(item.name) === serviceName
    );
  }
  const handleDistrictChange = (value) => {
    setDistrictValue(value);
    setTypeValue("");
    setSearchValue("");
    getFilterFacility(value, '');
  };

  const handleTypeChange = (value) => {
    setTypeValue(value);
    getFilterFacility(districtValue, value);
  };

  const handleSearchChange = (value) => {
    setSearchValue(value);
  };

  const handleEndSearchClick = () => {
    getFilterFacility(districtValue, typeValue);
  };

  if (!currentService) return <ErrorSection />;
  const primaryColor = window.getComputedStyle(document.documentElement).getPropertyValue('--primary');


  return (
    <>
      <div className="service-header">
        <Container>
          <div className="service-header-inner">
            <div className="left-div">
              <h6>{t("health facility title")}</h6>
              <span className="desc">
                {" "}
                <Link
                  to={`/${replaceSpaceWithDash(currentService.name)}/${cat}/${
                    currentService.id
                  }`}
                >
                  {currentService.name}
                </Link>{" "}
                | {t("health facility title")}
              </span>
            </div>
          </div>
        </Container>
      </div>
      <Container>
        <div className="service-page-detail">
          <form className="tcc-add">
            <Grid container columnSpacing={{ xs: 0, sm: 4 }}>
              <Grid item xs={12} sm={6} md={4}>
                <FormGroup className="form-group">
                  <FormLabel>{t("district label")}</FormLabel>
                  <Select
                    displayEmpty
                    value={districtValue}
                    onChange={(e) => handleDistrictChange(e.target.value)}
                  >
                    <MenuItem value="">
                      <span style={{ opacity: 0.4 }}>
                        {t("district option")}
                      </span>
                    </MenuItem>
                    {districtData?.list?.data?.map((item) => (
                      <MenuItem value={item.id} key={item.id}>
                        {item.district_name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormGroup>
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <FormGroup className="form-group">
                  <FormLabel>{t("facility type label")}</FormLabel>
                  <Select
                    displayEmpty
                    value={typeValue}
                    onChange={(e) => handleTypeChange(e.target.value)}
                  >
                    <MenuItem value="">
                      <span style={{ opacity: 0.4 }}>
                        {t("facility type option")}
                      </span>
                    </MenuItem>
                    {TypeData?.list?.data && TypeData.list.data.map((item) => (
                      <MenuItem value={item.id} key={item.id}>
                        {item.facility_type}
                      </MenuItem>
                    ))}
                  </Select>
                </FormGroup>
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <FormGroup className="form-group">
                  <FormLabel>{t("search facility lable")}</FormLabel>
                  <TextField
                    placeholder={t("search facility lable")}
                    onChange={(e) => handleSearchChange(e.target.value)}
                    className="form-control search"
                    type="text"
                    size="small"
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <SearchIcon />
                        </InputAdornment>
                      ),
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton onClick={handleEndSearchClick} style={{ backgroundColor: primaryColor, borderRadius: '6px', padding: "6px", marginRight: "-6px", marginLeft: "-15px" }}>
                            <SearchIcon style={{color: "white", width: 20, height: 20}}/>
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                </FormGroup>
              </Grid>
              <Grid item xs={12} sm={12} md={12}>
                {loading ? (
                  <div className="account-formSection notify-list-page form-loading no-br-bg">
                    <FormLoader />
                  </div>
                ) : (
                  <>
                      {facilityList.length > 0 &&
                      facilityList.map((item) => (
                        <div className="facility-list" key={item.id}>
                          <div className="name">{item.facility_name}</div>
                          <div className="desc">{item.common_name}</div>
                        </div>
                      ))}

                    {listSuccess && (
                      <div className="no-data-found">
                        <br />
                          {listSuccess}
                      </div>
                    )}

                      {(listSuccess === '' && facilityList.length === 0) && (
                      <div className="no-data-found">
                        <br />
                        {t("no data text")}
                      </div>
                    )}
                  </>
                )}
              </Grid>
            </Grid>
          </form>
        </div>
      </Container>
    </>
  );
};

export default HealthFacility;
