import React, { useState, useContext } from "react";
import { Grid, Tooltip } from "@mui/material";
import { Container } from "@mui/material";
import rightArrow from "../../../assets/Img/right-arr.png";
import leftArrow from "../../../assets/Img/right-arr.png";
import "./WhatsNew.css";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import useApiFetch from "../../../hooks/useApiFetch";
import { SERVICE_LISTALL } from "../../../utils/api-constant";
import LoadingPage from "../../SeviceScreen/LoadingPage";
import { replaceSpaceWithDash } from "../../../utils/custom";
import AuthContext from "../../../context/AuthContext";

function WhatsNew() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const authCtx = useContext(AuthContext);
  const [firstLimit, setFirstLimit] = useState(0);
  const [secondLimit, setSecondLimit] = useState(4);

  const { data, loading, error } = useApiFetch(
    process.env.REACT_APP_HOST_URL + SERVICE_LISTALL
  );

  if (loading) return <LoadingPage />;
  if (error) return;
  const newData = data.list.filter((item) => item.is_newly_added === 1);
  
  //console.log(newData);

  const fisrtCatgory = (cat) => {
    const array = cat.split(",");
    const category = replaceSpaceWithDash(array[0]);
    return category;
  };

  const handleForward = () => {
    if (newData.length > secondLimit) {
      setSecondLimit((prve) => prve + 4);
      setFirstLimit((prve) => prve + 5);
    }
  };

  const handleBackward = () => {
    if (secondLimit > 4) {
      setSecondLimit((prve) => prve - 4);
      setFirstLimit((prve) => prve - 5);
    }
  };

  const HandleNavigation = (item) => {
    if (item.service_type === 1 || item.service_type === "1") {
      if (item.reference_number !== null && item.reference_number !== ""){
        if (authCtx.isLogin) {
          navigate(`/all-services-detail/${replaceSpaceWithDash(item.name)}`, { state: { mdaName: item.category_name, serviceId: item.reference_number } })
        } else {
          navigate("/login", { state: { from: 3, navigateUrl: `/all-services-detail/${replaceSpaceWithDash(item.name)}`, mdaName: item.category_name, serviceId: item.reference_number  } });
        }
      } else {
        if (authCtx.isLogin) {
          navigate(`/all-services-detail/${replaceSpaceWithDash(item.name)}`, { state: { mdaName: item.category_name, serviceId: item.id } })
        } else {
          navigate("/login", { state: { from: 3, navigateUrl: `/all-services-detail/${replaceSpaceWithDash(item.name)}`, mdaName: item.category_name, serviceId: item.id } });
        }
      }
    } else {
      if (item.id === 1 || item.id === 2 || item.id === 3 || item.id === 4 || item.id === 5) {
        navigate(`/detail/services/${fisrtCatgory(item.category_name)}/${replaceSpaceWithDash(item.name)}`, { state: { anonymous_user: item.anonymous_user, } })
      }
    }
  };


  return (
    <section className="whatsNewSection">
      <Container maxWidth="lg">
        <Grid container spacing={2}>
          <Grid item xs={8}>
            <div className="sec-head">
              <h2>{t("whats new title")}</h2>
              <p>{t("whats new description")}</p>
            </div>
          </Grid>
          <Grid item xs={4}>
            <div className="arrowIcons">
              <img
                className={`leftArrow ${secondLimit === 4 ? "not-allow" : ""}`}
                src={leftArrow}
                onClick={handleBackward}
                alt="img"
              />
              <img
                className={`rightArrow ${
                  newData.length < secondLimit ? "not-allow" : ""
                }`}
                src={rightArrow}
                onClick={handleForward}
                alt="img"
              />
            </div>
          </Grid>
        </Grid>
      </Container>
      <Container maxWidth="lg">
        <Grid container spacing={2}>
          {newData.slice(firstLimit, secondLimit).map((item) => (
            <Grid item xs={12} sm={6} md={6} lg={3} key={item.id}>
              <div style={{ cursor: 'pointer' }}
                className="what-a"
                onClick={() => HandleNavigation(item)}
              >
                <div className="iconBox">
                  <div className="icon-wt-text">
                    <img
                      src={`${process.env.REACT_APP_HOST_URL}${process.env.REACT_APP_IMAGE_URL}${item.icon}`}
                      alt={item.name}
                    />
                    <Tooltip title={item.name} placement="top-start">
                      <h3 className="m1-head">{item.name}</h3>
                    </Tooltip>
                    <p className="min-txt">{item.description}</p>
                  </div>
                </div>
              </div>
            </Grid>
          ))}
          {newData.length === 0 && (
            <div className="no-data-found">
              <br />
              {t("no data text")}
              <br />
            </div>
          )}
        </Grid>
      </Container>
    </section>
  );
}
export default WhatsNew;
