import React, { useState, useEffect, useContext } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import MenuIcon from "@mui/icons-material/Menu";
import Container from "@mui/material/Container";
import Button from "@mui/material/Button";
import MenuItem from "@mui/material/MenuItem";
import "./Header.css";
import logo from "../../assets/Img/logo.png";
import MyAccountMenu from "./MyAccountMenu";
import search from "../../assets/Img/search.png";
import user1 from "../../assets/Img/user1.png";
import AuthContext from "../../context/AuthContext";
import NotificationIcon from "./NotificationIcon";
import { useTranslation } from "react-i18next";
import LanguageSelector from "./LanguageSelector";
import useApiFetch from "../../hooks/useApiFetch";
import { STATIC_HEADER } from "../../utils/api-constant";
import axios from "axios";
import { APP_LOGOUT, CHECK_TOKEN } from "../../utils/api-constant";

const pages = [
  "Home",
  "About Us",
  "Our Services",
  "Privacy policy",
  "Contact Us",
];
function Header() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const authCtx = useContext(AuthContext);
  const location = useLocation();
  const [anchorElNav, setAnchorElNav] = useState(null);
  const [apiToken, setApiToken] = useState(null);
  const [loginDetails, setLoginDetails] = useState([]);

  const { data, loading, error } = useApiFetch(
    process.env.REACT_APP_HOST_URL + STATIC_HEADER
  );
  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleCloseNavMenu = (from) => {
    setAnchorElNav(null);
    if (from === "home"){
      navigate("/");
    } else if (from === "ourservice") {
      navigate("/services-list");
    } else if (from === "mda") {
      navigate("/scheme-list");
    } else if (from === "contactus") {
      navigate("/cms/contactus");
    } else if (from === "login") {
      navigate("/login");
    } else if (from === "register") {
      navigate("/register");
    } else if(from === "makepayment"){
      if (authCtx.isLogin){
        navigate("/make-payment")
        }else{
        navigate("/login", { state: { from: 1, navigateUrl: "/make-payment" } });
      }
    } else if (from === "allservices") {
      navigate("/all-services")
    } 
  };

  const isActive = (value) => (location.pathname === value ? "active" : "");
  const isActive1 = (value) => (location.pathname.includes(value) ? "active" : "");

  useEffect(() => {
    if (
      localStorage.getItem("apiToken") !== "undefined" &&
      localStorage.getItem("apiToken") !== "" &&
      localStorage.getItem("apiToken") !== null
    ) {
      let apiToken = JSON.parse(localStorage.getItem("apiToken"));
      let loginData = JSON.parse(localStorage.getItem("userDetails"));
      setApiToken(apiToken);
      setLoginDetails(loginData);
    }
  }, [location]);
  useEffect(() => {
    let isAuth = JSON.parse(localStorage.getItem("apiToken"));
    if (isAuth === "" || isAuth === null || typeof apiToken === "undefined") {
      const currentUrl = window.location.pathname.split("/");
      if (currentUrl[1] === "my-account") {
        navigate("/no-access");
      }
    }
    if (apiToken !== '' && apiToken !== null
      && apiToken !== undefined) {
      console.log("CheckToken--> " + apiToken);
      CheckToken(apiToken);
    }
  }, [apiToken]);

  function CheckToken(apiToken) {
    const params = {
      headers: {
        "Content-Type": "application/json",
        needJson: 1,
        apiToken: apiToken,
      },
    };
    axios
      .get(process.env.REACT_APP_HOST_URL + CHECK_TOKEN, params)
      .then((response) => {
        console.log("CheckToken");
        console.log(response);
        if (response.data.success === false && response.data.user === null) {
          Logout(apiToken);
        }
      });
  }

  function Logout(apiToken) {
    const params = {
      headers: {
        needJson: 1,
        apiToken: apiToken,
      },
    };
    axios
      .get(process.env.REACT_APP_HOST_URL + APP_LOGOUT, params)
      .then((response) => {
        console.log("Logout");
        console.log(response);
        if (response.data.success) {
          localStorage.removeItem("apiToken");
          localStorage.removeItem("userDetails");
          authCtx.authLogout();
          navigate("/");
        }
      });
  }

  return (
    <div className="HeaderUI">
      <AppBar position="static" className="appbarui">
        <div className="con">
          <Container maxWidth="lg">
            <div className="Email-head">
              {!loading && !error ? (
                <div
                  className="para"
                  dangerouslySetInnerHTML={{ __html: data.list[0]?.content }}
                ></div>
              ) : (
                <div></div>
              )}
              <p className="para">
                {/* <span>
                  <button className="light">
                    Light <img src={star} className="star"></img>
                  </button>
                </span>{" "}
                | A | A+ | A- | English{" "}
                <img src={arrow} alt="arrow" className="arrow" /> */}
                <LanguageSelector />
              </p>
            </div>
          </Container>
        </div>

        <Container maxWidth="lg" className="head">
          <Toolbar disableGutters className="middle-menu">
            <div className="left">
              <a href="/">
                <img src={logo} className="log" />
              </a>
              <h2 href="/" className="title-text">
                {t("site title")}
                <span>{t("header site subtitle")}</span>
              </h2>
            </div>
            <div className="right">
              <img
                src={search}
                className="search mobile-search-icon"
                alt="search"
                onClick={() => navigate("/search")}
              />
              <Box>
                <IconButton
                  size="large"
                  aria-label="account of current user"
                  aria-controls="menu-appbar"
                  aria-haspopup="true"
                  onClick={handleOpenNavMenu}
                  className="ToggleIcon"
                  sx={{
                    display: {
                      xs: "block",
                      md: "none",
                      lg: "none",
                      md: "none",
                    },
                  }}
                >
                  <MenuIcon />
                </IconButton>
                <Menu
                  id="menu-appbar"
                  anchorEl={anchorElNav}
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "left",
                  }}
                  keepMounted
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "left",
                  }}
                  open={Boolean(anchorElNav)}
                  onClose={() => handleCloseNavMenu("close")}
                >
                  <MenuItem onClick={() => handleCloseNavMenu("home")}>
                    <Link className="mob-menu-item" to={"/"}>
                      {t("Home")}
                    </Link>
                  </MenuItem>
                  <MenuItem onClick={() => handleCloseNavMenu("ourservice")}>
                    <Link className="mob-menu-item" to={"/services-list"}>
                      {t("Our Services")}
                    </Link>
                  </MenuItem>
                  <MenuItem onClick={() => handleCloseNavMenu("mda")}>
                    <Link className="mob-menu-item" to={"/scheme-list"}>
                      {t("MDAs")}
                    </Link>
                  </MenuItem>
                  <MenuItem onClick={() => handleCloseNavMenu("makepayment")}>
                    <Link className="mob-menu-item" to={authCtx.isLogin ? "/make-payment" : "/login"}>
                      {t("Make Payment")}
                    </Link>
                  </MenuItem>
                  <MenuItem onClick={() => handleCloseNavMenu("allservices")}>
                    <Link className="mob-menu-item" to={"/all-services"}>
                      {t("all services")}
                    </Link>
                  </MenuItem>
                  <MenuItem onClick={() => handleCloseNavMenu("contactus")}>
                    <Link className="mob-menu-item" to={"/cms/contactus"}>
                      {t("Contact Us")}
                    </Link>
                  </MenuItem>
                  {!authCtx.isLogin && (
                    <MenuItem onClick={() => handleCloseNavMenu("login")}>
                      <Link className="mob-menu-item" to={"/login"}>
                        {t("login text")}
                      </Link>
                    </MenuItem>
                  )}
                  {!authCtx.isLogin && (
                    <MenuItem onClick={() => handleCloseNavMenu("register")}>
                      <Link className="mob-menu-item" to={"/register"}>
                        {t("register text")}
                      </Link>
                    </MenuItem>
                  )}
                </Menu>
              </Box>
              <Box
                className="menu-items"
                sx={{
                  display: {
                    xs: "none",
                    md: "block",
                    lg: "block",
                    md: "block",
                  },
                }}
              >
                {/* {pages.map((page) => (
                  <Button
                    className='menu-text'
                    key={page}
                    onClick={handleCloseNavMenu}
                  >
                    {page}
                  </Button>
                ))} */}
                <Button
                  id="home-button"
                  className={isActive("/")}
                  onClick={() => navigate("/")}
                >
                  {t("Home")}
                </Button>
                <Button
                  id="ourservice-button"
                  className={isActive("/services-list")}
                  onClick={() => navigate("/services-list")}
                >
                  {t("Our Services")}
                </Button>
                <Button
                  id="schemes-button"
                  className={isActive("/scheme-list")}
                  onClick={() => navigate("/scheme-list")}
                >
                  {t("MDAs")}
                </Button>
                <Button
                  id="payment-button"
                  className={isActive("/make-payment")}
                  onClick={() => authCtx.isLogin ? navigate("/make-payment") : navigate("/login", { state: { from: 1, navigateUrl: "/make-payment" } })}
                >
                  {t("Make Payment")}
                </Button>
                <Button
                  id="payment-button"
                  className={isActive1("/all-services")}
                  onClick={() => navigate("/all-services")}
                >
                  {t("all services")}
                </Button>
                <Button
                  id="contact-button"
                  className={isActive("/cms/contactus")}
                  onClick={() => navigate("/cms/contactus")}
                >
                  {t("Contact Us")}
                </Button>
                <img
                  src={search}
                  className="search"
                  alt="search"
                  onClick={() => navigate("/search")}
                />
              </Box>
              {authCtx.isLogin && (
                <>
                  <MyAccountMenu loginDetails={loginDetails} />
                  <NotificationIcon />
                </>
              )}
              {!authCtx.isLogin && (
                <Box
                  className="border"
                  sx={{
                    display: {
                      xs: "none",
                      md: "block",
                      lg: "block",
                      md: "block",
                    },
                  }}
                >
                  <Button
                    className="btn btn-primary"
                    onClick={() => navigate("/login")}
                  >
                    <img src={user1} className="user" alt="user" />
                    {t("login text")}
                  </Button>
                  <span className="change">/</span>
                  <Button
                    className="btn btn-secondary"
                    onClick={() => navigate("/register")}
                  >
                    {t("register text")}
                  </Button>
                </Box>
              )}
            </div>
          </Toolbar>
        </Container>
      </AppBar>
    </div>
  );
}

export default Header;
