import { Container, FormGroup, FormLabel, InputAdornment, TextField, Typography, Box, styled, RadioGroup, Radio, FormControlLabel, Select, MenuItem } from "@mui/material";
import React, { useState, useEffect, useContext, useRef } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Grid } from "@mui/material";
import "./PassportService.css";
import LoadingPage from "../SeviceScreen/LoadingPage";
import ErrorSection from "../Error/ErrorSection";
import { countries } from "../../Files/Country";
import LoadingButton from "@mui/lab/LoadingButton";
import PersonIcon from '@mui/icons-material/Person';
import PlaceIcon from '@mui/icons-material/Place';
import TaskAltIcon from '@mui/icons-material/TaskAlt';
import FormControl from "@mui/material/FormControl";

import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import axios from "axios";
import { PASSPORT_SAVE, SERVICES_DETAIL, ALL_SERVICES_LIST } from "../../utils/api-constant";
import FileUpload from "./FileUpload";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import EventIcon from "@mui/icons-material/Event";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Ministry from "../../assets/Img/all_service_ministry.png";
import Price from "../../assets/Img/all_service_price.png";

function PassportService() {

  const location = useLocation();
  const searchText = location.state?.searchText || "";
  console.log("location state--> ", location.state)
  const { t } = useTranslation();
  const [Loading, setLoading] = useState(true);
  const [Error, setError] = useState(null);
  const [Tab, setTab] = useState({
    personalInformation: true,
    placeOfOrigin: false,
    declaration: false,
  });
  const [SurName, setSurName] = useState({
    value: '',
    error: ''
  });
  const [OtherName, setOtherName] = useState({
    value: '',
    error: ''
  });
  const [MaidenName, setMaidenName] = useState({
    value: '',
    error: ''
  });
  const [DOB, setDOB] = useState({
    value: null,
    error: ''
  });
  const [PlaceOfBirth, setPlaceOfBirth] = useState({
    value: '',
    error: ''
  });
  const [District, setDistrict] = useState({
    value: '',
    error: ''
  });
  const [Country, setCountry] = useState({
    value: '',
    error: ''
  });
  const [Nationality, setNationality] = useState({
    value: '',
    error: ''
  });
  const [NationalId, setNationalId] = useState({
    value: '',
    error: ''
  });
  const [Email, setEmail] = useState({
    value: '',
    error: ''
  });
  const [Gender, setGender] = useState({
    value: '',
    error: ''
  });
  const [SpecialPeculiarities, setSpecialPeculiarities] = useState({
    value: '',
    error: ''
  });
  const [PresentAddress, setPresentAddress] = useState({
    value: '',
    error: ''
  });
  const [ColorOfEyes, setColorOfEyes] = useState({
    value: '',
    error: ''
  });
  const [Height, setHeight] = useState({
    value: '',
    error: ''
  });
  const [Occupation, setOccupation] = useState({
    value: '',
    error: ''
  });
  const [Country1, setCountry1] = useState({
    value: '',
    error: ''
  });
  const [PhoneNumber, setPhoneNumber] = useState({
    value: '',
    error: ''
  });
  const [District1, setDistrict1] = useState({
    value: '',
    error: ''
  });

  // tab 2 

  const [VillageTown, setVillageTown] = useState({
    value: '',
    error: ''
  });
  const [TA, setTA] = useState({
    value: '',
    error: ''
  });
  const [ApplicantDistrict, setApplicantDistrict] = useState({
    value: '',
    error: ''
  });
  const [ApplicantCountry, setApplicantCountry] = useState({
    value: '',
    error: ''
  });
  const [MalawiCitizenShip, setMalawiCitizenShip] = useState({
    value: '',
    error: ''
  });
  const [FatherVillageTA, setFatherVillageTA] = useState({
    value: '',
    error: ''
  });
  const [FatherCountry, setFatherCountry] = useState({
    value: '',
    error: ''
  });
  const [FatherDistrict, setFatherDistrict] = useState({
    value: '',
    error: ''
  });
  const [FatherNationalId, setFatherNationalId] = useState({
    value: '',
    error: ''
  });
  const [MotherVillageTA, setMotherVillageTA] = useState({
    value: '',
    error: ''
  });
  const [MotherCountry, setMotherCountry] = useState({
    value: '',
    error: ''
  });
  const [MotherDistrict, setMotherDistrict] = useState({
    value: '',
    error: ''
  });
  const [MotherNationalId, setMotherNationalId] = useState({
    value: '',
    error: ''
  });

  const [consent, setConsent] = useState({
    value: '',
    error: ''
  });
  const [fullNameMinor, setFullNameMinor] = useState({
    value: '',
    error: ''
  });
  const [relationshipMinor, setRelationshipMinor] = useState({
    value: '',
    error: ''
  });
  //tab 3
  const [agreed, setAgreed] = useState({
    value: false,
    error: ''
  });
  const [agreedDate, setagreedDate] = useState({
    value: '',
    error: ''
  });
  const [open, setOpen] = useState(false);
  const [popupText, setPopupText] = useState({});
  const descriptionElementRef = useRef(null);
  const navigate = useNavigate();
  const [scroll, setScroll] = useState("paper");

  const [ServiceDetail, setServiceDetail] = useState({});
  const { mdaName, serviceId } = location.state !== null && location.state !== undefined ? location.state : "/";
  console.log("mdaName--> " + mdaName);
  const [MdaName, setMdaName] = useState(mdaName ?? '');
  const [PaymentSuccessAlert, setPaymentSuccessAlert] = useState({
    success: false,
    data: ""
  });
  const [PaymentFailedAlert, setPaymentFailedAlert] = useState({
    success: false,
    data: ""
  });
  const [UserDetails, setUserDetails] = useState({});
  const [SelectedPayment, setSelectedPayment] = useState({});
  const [PaymentOptionArray, setPaymentOptionArray] = useState([]);
  const [PaymentLoading, setPaymentLoading] = useState(false);
  const url1 = window.location.href;
  const hashPart = url1.split('#')[1];
  const parts = hashPart.split('/');
  const ScreenName = parts[2];
  console.log('ScreenName--> ', ScreenName);
  const [SelectedPassportType, setSelectedPassportType] = useState('');
  const [SelectedServiceType, setSelectedServiceType] = useState('');
  const [SelectedBookletType, setSelectedBookletType] = useState('');

  const getServicesDetail = (apiToken, serviceId) => {
    const params = {
      headers: {
        needJson: 1,
        apiToken: apiToken,
        "Content-Type": "application/json",
      },
    };
    axios
      .get(process.env.REACT_APP_HOST_URL + SERVICES_DETAIL + serviceId, params)
      .then((response) => {
        if (response.data.services !== null) {
          setServiceDetail(response.data.services);
          
        }
        setLoading(false);
      })
      .catch((e) => {
        setLoading(false);
        setError(e.message);
        console.log(e)
      });
  };

  const getAllServicesList = (apiToken) => {
    const params = {
      headers: {
        needJson: 1,
        apiToken: apiToken,
        "Content-Type": "application/json",
      },
    };
    axios
      .get(`${process.env.REACT_APP_HOST_URL + ALL_SERVICES_LIST}&start=0&limit=0`, params)
      .then((response) => {
        const ServiceName = ScreenName?.toLowerCase().replace(/-/g, " ");
        const matchingServices = response?.data?.list
          .map(item => {
            const selectedService = item.services.find(service =>
              service.serviceName.toLowerCase().replace(/-/g, " ") === ServiceName
            );
            return selectedService
              ? { mdaName: item.mdaName, service: selectedService }
              : null;
          })
          .filter(Boolean);
        setMdaName(matchingServices ? matchingServices[0]?.mdaName : '');
        console.log("matchingServices--> ", matchingServices?.length);
        if (matchingServices?.length > 0) {
          console.log("matchingServices12--> ", matchingServices[0]?.service);
          if (matchingServices[0]?.service?.reference_number !== null && matchingServices[0]?.service?.reference_number !== "") {
            getServicesDetail(apiToken, matchingServices[0]?.service?.reference_number);
          } else {
            getServicesDetail(apiToken, matchingServices[0]?.service?.serviceId);
          }
        } else {
          setLoading(false);
        }

      })
      .catch((e) => {
        setLoading(false);
        setError(e.message);
      });
  };

  useEffect(() => {
    let apiToken = JSON.parse(localStorage.getItem("apiToken"));
    if (serviceId !== undefined && serviceId !== null) {
      setMdaName(mdaName);
      getServicesDetail(apiToken, serviceId);
    } else {
      getAllServicesList(apiToken)
    }
  }, []);

  function PassportSave() {
    const apiToken = JSON.parse(localStorage.getItem("apiToken"));

    const requestBody = {
      passport_type: SelectedPassportType?.name ?? '',
      service_type: SelectedServiceType?.name ?? '',
      booklet_type: SelectedBookletType?.name ?? '',
      personal_info: {
        surname: SurName.value,
        other_names: OtherName.value,
        maiden_names: MaidenName.value,
        date_of_birth: DOB.value ?? '',
        gender: Gender.value,
        place_of_birth: PlaceOfBirth.value,
        birth_district: District.value,
        birth_country: Country.value,
        color_of_eyes: ColorOfEyes.value,
        height: parseFloat(Height.value) || 0,
        occupation: Occupation.value,
        nationality: Nationality.value,
        national_id_no: NationalId.value,
        special_peculiarities: SpecialPeculiarities.value,
        present_address: PresentAddress.value,
        present_district: District1.value,
        present_country: Country1.value,
        phone_no: PhoneNumber.value,
        email_address: Email.value,
      },
      place_of_origin: {
        village_town: VillageTown.value,
        ta: TA.value,
        district: ApplicantDistrict.value,
        country: ApplicantCountry.value,
        citizenship_by: MalawiCitizenShip.value,
      },
      father_origin: {
        village_ta: FatherVillageTA.value,
        country: FatherCountry.value,
        district: FatherDistrict.value,
        national_id_no: FatherNationalId.value,
      },
      mother_origin: {
        village_ta: MotherVillageTA.value,
        country: MotherCountry.value,
        district: MotherDistrict.value,
        national_id_no: MotherNationalId.value,
      },
      guardian_consent: {
        consent_given: agreed.value,
        guardian_name: "Jane Malawi", // Replace with a variable if needed
        relationship: "Mother",       // Replace with a variable if needed
      },
      declaration: {
        agreed: agreed.value,
        date_time: new Date().toISOString(), // Automatically set the current date and time
      },
    };

    axios
      .post(
        `${process.env.REACT_APP_HOST_URL}${PASSPORT_SAVE}`,
        requestBody,
        {
          headers: {
            needJson: 1,
            apiToken: apiToken,
            "Content-Type": "application/json",
          },
        }
      )
      .then((response) => {
        console.log(response.data);
        if (response.data.success) {
          // Handle success
          setPopupText(response.data);
          setOpen(true);
        } else if (response.data.success === false) {
          // Handle failure
        } else {
          setError("true");
        }
      })
      .catch((e) => {
        setError(e.message);
      });
  }


  const validateTextChange = (e, from) => {
    var value = e.target.value;
    if (from === "SurName") {
      setSurName({
        value: value.trim(),
        error: value.trim() === "" ? t("required alert") : "",
      });
    } else if (from === "OtherName") {
      setOtherName({
        value: value.trim(),
        error: value.trim() === "" ? t("required alert") : "",
      });
    } else if (from === "MaidenName") {
      setMaidenName({
        value: value.trim(),
        error: value.trim() === "" ? t("required alert") : "",
      });
    } else if (from === "PlaceOfBirth") {
      setPlaceOfBirth({
        value: value.trim(),
        error: value.trim() === "" ? t("required alert") : "",
      });
    } else if (from === "District") {
      setDistrict({
        value: value.trim(),
        error: value.trim() === "" ? t("required alert") : "",
      });
    } else if (from === "Nationality") {
      setNationality({
        value: value.trim(),
        error: value.trim() === "" ? t("required alert") : "",
      });
    } else if (from === "NationalId") {
      setNationalId({
        value: value.trim(),
        error: value.trim() === "" ? t("required alert") : "",
      });
    } else if (from === "Email") {
      setEmail({
        value: value.trim(),
        error: value.trim() === "" ? t("required alert") : "",
      });
    } else if (from === "SpecialPeculiarities") {
      setSpecialPeculiarities({
        value: value.trim(),
        error: value.trim() === "" ? t("required alert") : "",
      });
    } else if (from === "PresentAddress") {
      setPresentAddress({
        value: value.trim(),
        error: value.trim() === "" ? t("required alert") : "",
      });
    } else if (from === "ColorOfEyes") {
      setColorOfEyes({
        value: value.trim(),
        error: value.trim() === "" ? t("required alert") : "",
      });
    } else if (from === "Height") {
      setHeight({
        value: value.trim(),
        error: value.trim() === "" ? t("required alert") : "",
      });
    } else if (from === "Occupation") {
      setOccupation({
        value: value.trim(),
        error: value.trim() === "" ? t("required alert") : "",
      });
    } else if (from === "PhoneNumber") {
      setPhoneNumber({
        value: value.trim(),
        error: value.trim() === "" ? t("required alert") : "",
      });
    } else if (from === "District1") {
      setDistrict1({
        value: value.trim(),
        error: value.trim() === "" ? t("required alert") : "",
      });
    }
  };

  const validateTextChangeTab2 = (e, from) => {
    var value = e.target.value;
    if (from === "VillageTown") {
      setVillageTown({
        value: value.trim(),
        error: value.trim() === "" ? t("required alert") : "",
      });
    } else if (from === "TA") {
      setTA({
        value: value.trim(),
        error: value.trim() === "" ? t("required alert") : "",
      });
    } else if (from === "ApplicantDistrict") {
      setApplicantDistrict({
        value: value.trim(),
        error: value.trim() === "" ? t("required alert") : "",
      });
    } else if (from === "MalawiCitizenShip") {
      setMalawiCitizenShip({
        value: value.trim(),
        error: value.trim() === "" ? t("required alert") : "",
      });
    } else if (from === "FatherVillageTA") {
      setFatherVillageTA({
        value: value.trim(),
        error: value.trim() === "" ? t("required alert") : "",
      });
    } else if (from === "FatherDistrict") {
      setFatherDistrict({
        value: value.trim(),
        error: value.trim() === "" ? t("required alert") : "",
      });
    } else if (from === "FatherNationalId") {
      setFatherNationalId({
        value: value.trim(),
        error: value.trim() === "" ? t("required alert") : "",
      });
    } else if (from === "MotherVillageTA") {
      setMotherVillageTA({
        value: value.trim(),
        error: value.trim() === "" ? t("required alert") : "",
      });
    } else if (from === "MotherDistrict") {
      setMotherDistrict({
        value: value.trim(),
        error: value.trim() === "" ? t("required alert") : "",
      });
    } else if (from === "MotherNationalId") {
      setMotherNationalId({
        value: value.trim(),
        error: value.trim() === "" ? t("required alert") : "",
      });
    } else if(from === "consent")
    {
      const isChecked = e.target.checked;
      setConsent({
        value: isChecked,
        error: isChecked ? "" : "",
      });
    }
    else if(from === "fullNameMinor")
    {
      setFullNameMinor({
        value: value.trim(),
        error: value.trim() === "" ? t("required alert") : "",
      });
    }
    else if(from === "relationshipMinor")
    {
      setRelationshipMinor({
        value: value.trim(),
        error: value.trim() === "" ? t("required alert") : "",
      });
    }
  };

  const validateTextChangeTab3 = (e) => {
    const isChecked = e.target.checked;
    setAgreed({
      value: isChecked,
      error: isChecked ? "" : "You must agree to proceed",
    });
  }

  const validateFields = (fields) => {
    let isValid = true;
    fields.forEach(({ value, setter }) => {
      if (!value) {
        isValid = false;
        setter((prev) => ({
          ...prev,
          error: t("required alert"),
        }));
      } else {
        setter((prev) => ({
          ...prev,
          error: "",
        }));
      }
    });
    console.log("Validation Result -->", isValid);
    return isValid;
  };


  function validatePersonalInformationSubmit() {
    const validateField = [
      { value: SurName.value, setter: setSurName },
      { value: OtherName.value, setter: setOtherName },
      { value: MaidenName.value, setter: setMaidenName },
      { value: DOB.value, setter: setDOB },
      { value: PlaceOfBirth.value, setter: setPlaceOfBirth },
      { value: District.value, setter: setDistrict },
      { value: Nationality.value, setter: setNationality },
      { value: NationalId.value, setter: setNationalId },
      { value: Email.value, setter: setEmail },
      { value: SpecialPeculiarities.value, setter: setSpecialPeculiarities },
      { value: PresentAddress.value, setter: setPresentAddress },
      { value: ColorOfEyes.value, setter: setColorOfEyes },
      { value: Height.value, setter: setHeight },
      { value: Occupation.value, setter: setOccupation },
      { value: PhoneNumber.value, setter: setPhoneNumber },
      { value: District1.value, setter: setDistrict1 },
      { value: Country.value, setter: setCountry },
      { value: Country1.value, setter: setCountry1 },
      { value: Gender.value, setter: setGender },
    ];
    console.log("valFields", validateField);
    const formIsValid = validateFields(validateField);
    console.log("formIsValid--> ", formIsValid)
    return formIsValid;
  }

  function validatePlaceOfOriginSubmit() {
    const validateField = [
      { value: VillageTown.value, setter: setVillageTown },
      { value: TA.value, setter: setTA },
      { value: ApplicantDistrict.value, setter: setApplicantDistrict },
      { value: ApplicantCountry.value, setter: setApplicantCountry },
      { value: MalawiCitizenShip.value, setter: setMalawiCitizenShip },
      { value: FatherVillageTA.value, setter: setFatherVillageTA },
      { value: FatherCountry.value, setter: setFatherCountry },
      { value: FatherDistrict.value, setter: setFatherDistrict },
      { value: FatherNationalId.value, setter: setFatherNationalId },
      { value: MotherVillageTA.value, setter: setMotherVillageTA },
      { value: MotherCountry.value, setter: setMotherCountry },
      { value: MotherDistrict.value, setter: setMotherDistrict },
      { value: MotherNationalId.value, setter: setMotherNationalId },
    ];
    console.log("valFields", validateField);
    const formIsValid = validateFields(validateField);
    console.log("formIsValid--> ", formIsValid)
    return formIsValid;
  }

  const GenderArray = [
    { id: 1, name: "Male" },
    { id: 2, name: "Female" },
  ];

  const MalawiCitizenShipArray = [
    { id: 1, name: "Birth" },
    { id: 2, name: "Decent" },
    { id: 3, name: "Registration" }
  ];

  const BpIcon = styled('span')(({ theme }) => ({
    borderRadius: '50%',
    width: 20,
    height: 20,
    boxShadow:
      theme.palette.mode === 'dark'
        ? '0 0 0 1px rgb(16 22 26 / 40%)'
        : 'inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)',
    backgroundColor: theme.palette.mode === 'dark' ? '#394b59' : '#f5f8fa',
    backgroundImage:
      theme.palette.mode === 'dark'
        ? 'linear-gradient(180deg,hsla(0,0%,100%,.05),hsla(0,0%,100%,0))'
        : 'linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))',
    '.Mui-focusVisible &': {
      outline: '2px auto rgba(19,124,189,.6)',
      outlineOffset: 2,
    },
    'input:hover ~ &': {
      backgroundColor: theme.palette.mode === 'dark' ? '#30404d' : '#ebf1f5',
    },
    'input:disabled ~ &': {
      boxShadow: 'none',
      background:
        theme.palette.mode === 'dark' ? 'rgba(57,75,89,.5)' : 'rgba(206,217,224,.5)',
    },
  }));

  const BpCheckedIcon = styled(BpIcon)({
    backgroundColor: '#137cbd',
    backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
    '&::before': {
      display: 'block',
      width: 20,
      height: 20,
      backgroundImage: 'radial-gradient(#fff,#fff 28%,transparent 32%)',
      content: '""',
    },
    'input:hover ~ &': {
      backgroundColor: '#106ba3',
    },
  });

  const HandlePaymentClick = (item, from) => {
    if (from === "Gender") {
      setGender({
        value: item.name,
        error: item.name === "" ? t("required alert") : "",
      });
    } else if (from === "MalawiCitizenShip") {
      setMalawiCitizenShip({
        value: item.name,
        error: item.name === "" ? t("required alert") : "",
      });
    }

  };

  const HandleCountryChange = (value, from) => {
    if (from === "Country") {
      setCountry({
        value: value,
        error: value === "" ? t("required alert") : "",
      });
    } else if (from === "Country1") {
      setCountry1({
        value: value,
        error: value === "" ? t("required alert") : "",
      });
    } else if (from === "ApplicantCountry") {
      setApplicantCountry({
        value: value,
        error: value === "" ? t("required alert") : "",
      });
    } else if (from === "FatherCountry") {
      setFatherCountry({
        value: value,
        error: value === "" ? t("required alert") : "",
      });
    } else if (from === "MotherCountry") {
      setMotherCountry({
        value: value,
        error: value === "" ? t("required alert") : "",
      });
    }
  };

  const getActiveTabIndex = () => {
    const tabs = Object.keys(Tab);
    return tabs.findIndex((key) => Tab[key]);
  };

  const HandleBackClick = () => {
    const tabs = Object.keys(Tab);
    const currentIndex = getActiveTabIndex();
    if (currentIndex > 0) {
      const prevTab = tabs[currentIndex - 1];
      const currentTab = tabs[currentIndex];
      setTab({ ...Tab, [currentTab]: false, [prevTab]: true });
    }
  }

  const HandleNextClick = () => {
    let proceed = false;
    if(Tab.personalInformation){
      proceed = validatePersonalInformationSubmit();
    } else if (Tab.placeOfOrigin) {
      proceed = validatePlaceOfOriginSubmit();
    } else if (Tab.declaration) {
      // declaration agree pannunathaan api hit pannanum
      PassportSave();
    }
    console.log("proceed--> ", proceed)
    console.log("Tab--> ", Tab)
    if (proceed){
      const tabs = Object.keys(Tab);
      const currentIndex = getActiveTabIndex();
      if (currentIndex < tabs.length - 1) {
        const nextTab = tabs[currentIndex + 1];
        const currentTab = tabs[currentIndex];
        setTab({ ...Tab, [currentTab]: false, [nextTab]: true });
      }
    }

  }

  const validateDob = (e) => {
    setDOB({
      value: e,
      error: e === "" ? t("required alert") : "",
    });
  };

  const handleClose = () => {
    setOpen(false);
    navigate("/all-services")
  };

  const PassportTypeArray = [
    { id: 1, name: "Ordinary" },
    { id: 2, name: "Diplomatic" },
    { id: 3, name: "Service" },
    { id: 4, name: "Minor" }
  ]

  const ServiceTypeArray = [
    { id: 1, name: "Normal" },
    { id: 2, name: "Express" },
    { id: 3, name: "Urgent" }
  ]

  const BookletTypeArray = [
    { id: 1, name: "36 Pages" },
    { id: 2, name: "48 Pages" }
  ]

  if (Error) return <ErrorSection />;

  if (Loading) return <LoadingPage />;

  return (
    <div className="all-service applyPass list">

      <div className="all-service-header">
        <Container>
          <div className="all-service-header-inner">
            <div className="left-div">
              <h6 className="left-serviceall-text1">{"Passport Service"}</h6>
            </div>
          </div>
        </Container>
      </div>
      <Container>
        <section className="all-services-view">
          <div className="service-page-detail">
          <div className="container">
            <h1 className="service-name">{Object.keys(ServiceDetail).length > 0 && (ServiceDetail.serviceName || '')}</h1>
            <h2 className="label">{t("about this service")}</h2>
            <p className="description">{Object.keys(ServiceDetail).length > 0 && (ServiceDetail.description || '')}
            </p>

            <div className="mbl-con">
              <div className="info-containers">
                <Grid xs={12} md={6}>
                  <div className="icon-item">
                    <img src={Ministry} alt='' className="img-ministry" />
                    <span className="info-name-head">{t("provided by")}<span className="info-name">{MdaName || ''}</span></span>
                  </div>
                </Grid>
              </div>

            </div>
            {ServiceDetail.uid === "98de7522-c0a9-391d-907c-e1d81b370e48" || ServiceDetail.uid === "718b9f88-af8b-3b87-be84-8f4880eebc6d" ? <div className="form-type">
              <FormGroup className="form-group flex-field-form">
                <FormGroup className="form-group mda-group flex-field-grp" style={{ paddingTop: '0px' }}>
                  <FormLabel style={{ minWidth: 50 }} required className='list-item-text'>{t('passport type')} </FormLabel>
                  <Select className="form-select-ps" value={SelectedPassportType.name} displayEmpty>
                    <MenuItem disabled>
                      <span style={{ opacity: 0.4 }}>{t("select")}</span>
                    </MenuItem>
                    {PassportTypeArray.length > 0 ? (
                      PassportTypeArray.map((item, index) =>
                        <MenuItem
                          onClick={(e) => setSelectedPassportType(item)}
                          key={item.id}
                          value={item.id}
                        >
                          {item.name}
                        </MenuItem>
                      )
                    ) : (
                      <MenuItem disabled>No data available</MenuItem>
                    )}
                  </Select>
                </FormGroup>
                <FormGroup className="form-group mda-group flex-field-grp">
                  <FormLabel style={{ minWidth: 50 }} required className='list-item-text'>{t('service type')} </FormLabel>
                  <Select className="form-select-ps" value={SelectedServiceType.name} displayEmpty>
                    <MenuItem disabled>
                      <span style={{ opacity: 0.4 }}>{t("select")}</span>
                    </MenuItem>
                    {ServiceTypeArray.length > 0 ? (
                      ServiceTypeArray.map((item, index) =>
                        <MenuItem
                          onClick={(e) => setSelectedServiceType(item)}
                          key={item.id}
                          value={item.id}
                        >
                          {item.name}
                        </MenuItem>
                      )
                    ) : (
                      <MenuItem disabled>No data available</MenuItem>
                    )}
                  </Select>
                </FormGroup>
                <FormGroup className="form-group mda-group flex-field-grp">
                  <FormLabel style={{ minWidth: 50 }} required className='list-item-text'>{t('booklet type')} </FormLabel>
                  <Select className="form-select-ps" value={SelectedBookletType.name} displayEmpty>
                    <MenuItem disabled>
                      <span style={{ opacity: 0.4 }}>{t("select")}</span>
                    </MenuItem>
                    {BookletTypeArray.length > 0 ? (
                      BookletTypeArray.map((item, index) =>
                        <MenuItem
                          onClick={(e) => setSelectedBookletType(item)}
                          key={item.id}
                          value={item.id}
                        >
                          {item.name}
                        </MenuItem>
                      )
                    ) : (
                      <MenuItem disabled>No data available</MenuItem>
                    )}
                  </Select>
                </FormGroup>
              </FormGroup>
            </div> : null}
            {Object.keys(ServiceDetail).length > 0 && <div className="info-item  icon-item passPortAmount">
                <span className='list-item-text'>{"Amount to Pay: "}<span className="info-amount">{t("MWK") + ServiceDetail.amount || ''}</span></span>
            </div>}
          </div>
            <div className="passportAddPage">
              <div className="tab-head">
                <div className={`ttl ${Tab.personalInformation ? "active" : ""}`}>
                  <div className="img-step">
                    {/* <img src={Ministry} alt='' className="" /> */}
                    <PersonIcon />
                  </div>
                  <div className="cont-step">
                    <span>{t("step1")}</span>
                    <span className="tab-title-color">{t("personal information")}</span>
                  </div>
                </div>
                <div className={`ttl ${Tab.placeOfOrigin ? "active" : ""}`}>
                  <div className="img-step">
                    {/* <img src={Ministry} alt='' className="" /> */}
                    <PlaceIcon />
                  </div>
                  <div className="cont-step">
                    <span>{t("step2")}</span>
                    <span className="tab-title-color">{t("place of origin")}</span>
                  </div>
                </div>
                <div className={`ttl ${Tab.declaration ? "active" : ""}`}>
                  <div className="img-step">
                    {/* <img src={Ministry} alt='' className="" /> */}
                    <TaskAltIcon />
                  </div>
                  <div className="cont-step">
                    <span>{t("step3")}</span>
                    <span className="tab-title-color">{t("declaration")}</span>
                  </div>
                </div>
              </div>
              {Tab.personalInformation && <Grid container spacing={2} className="grid passportPage personal">
                <Grid item xs={12}>
                  <Grid container spacing={2} className="grid">
                    <Grid item xs={12}>
                      <Grid container spacing={2} sx={{ mt: 0 }}>
                        <Grid item xs={12} sm={8} md={8}>
                          <Grid container spacing={2} sx={{ mt: 0 }}>
                            <Grid item xs={12} sm={6} md={6}>
                              <Box sx={{ display: 'flex', flexDirection: 'column', p: 1, mt: -1 }}>
                                <Typography className="list-item-text" variant="body1">
                                  {t('surname')}
                                </Typography>
                                <TextField
                                  type="text"
                                  size="small"
                                  value={SurName.value}
                                  onChange={(e) => validateTextChange(e, "SurName")} />
                                <div className="errorMsg">{SurName.error}</div>
                              </Box>
                            </Grid>
                            <Grid item xs={12} sm={6} md={6}>
                              <Box sx={{ display: 'flex', flexDirection: 'column', p: 1, mt: -1 }}>
                                <Typography className="list-item-text" variant="body1">
                                  {t('other name')}
                                </Typography>
                                <TextField
                                  type="text"
                                  size="small"
                                  value={OtherName.value}
                                  onChange={(e) => validateTextChange(e, "OtherName")} />
                                <div className="errorMsg">{OtherName.error}</div>
                              </Box>
                            </Grid>
                            <Grid item xs={12} sm={6} md={6}>
                              <Box sx={{ display: 'flex', flexDirection: 'column', p: 1, mt: -1 }}>
                                <Typography className="list-item-text" variant="body1">
                                  {t('maiden name')}
                                </Typography>
                                <TextField
                                  type="text"
                                  size="small"
                                  value={MaidenName.value}
                                  onChange={(e) => validateTextChange(e, "MaidenName")} />
                                <div className="errorMsg">{MaidenName.error}</div>
                              </Box>
                            </Grid>
                            <Grid item xs={12} sm={6} md={6}>
                              <Box sx={{ display: 'flex', flexDirection: 'column', p: 1, mt: -1 }}>
                                <Typography className="list-item-text" variant="body1">
                                  {t('dob')}
                                </Typography>
                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                  <DesktopDatePicker
                                    sx={{ height: '45px' }}
                                    format="DD/MM/YYYY"
                                    value={DOB?.value ?? null}
                                    className="datepcik"
                                    onChange={(e) => validateDob(e)}
                                    renderInput={(params) => (
                                      <div>
                                        <EventIcon />
                                        <input {...params.inputProps} />
                                      </div>
                                    )}
                                    disableFuture={true}
                                  />
                                </LocalizationProvider>
                                <div className="errorMsg">{DOB.error}</div>
                              </Box>
                            </Grid>
                            <Grid item xs={12} sm={6} md={6}>
                              <Box sx={{ display: 'flex', flexDirection: 'column', p: 1, mt: -1 }}>
                                <Typography className="list-item-text" variant="body1">
                                  {t('place of birth')}
                                </Typography>
                                <TextField
                                  type="text"
                                  size="small"
                                  value={PlaceOfBirth.value}
                                  onChange={(e) => validateTextChange(e, "PlaceOfBirth")} />
                                <div className="errorMsg">{PlaceOfBirth.error}</div>
                              </Box>
                            </Grid>
                            <Grid item xs={12} sm={6} md={6}>
                              <Box sx={{ display: 'flex', flexDirection: 'column', p: 1, mt: -1 }}>
                                <Typography className="list-item-text" variant="body1">
                                  {t('district')}
                                </Typography>
                                <TextField
                                  type="text"
                                  size="small"
                                  value={District.value}
                                  onChange={(e) => validateTextChange(e, "District")} />
                                <div className="errorMsg">{District.error}</div>
                              </Box>
                            </Grid>
                            <Grid item xs={12} sm={6} md={6}>
                              <Box sx={{ display: 'flex', flexDirection: 'column', p: 1, mt: -1 }}>
                                <Typography className="list-item-text" variant="body1">
                                  {t('country')}
                                </Typography>
                                <Select
                                  value={Country.value}
                                  size="small"
                                  onChange={(e) => HandleCountryChange(e.target.value, 'Country')} >
                                  <MenuItem value="">
                                    <em>{t("choose a country option")}</em>
                                  </MenuItem>
                                  {countries.map((e, key) => {
                                    return (
                                      <MenuItem key={key} value={e.label}>
                                        {e.label}
                                      </MenuItem>
                                    );
                                  })}
                                </Select>
                                <div className="errorMsg">{Country.error}</div>
                              </Box>
                            </Grid>
                            <Grid item xs={12} sm={6} md={6}>
                              <Box sx={{ display: 'flex', flexDirection: 'column', p: 1, mt: -1 }}>
                                <Typography className="list-item-text" variant="body1">
                                  {t('nationality')}
                                </Typography>
                                <TextField
                                  type="text"
                                  size="small"
                                  value={Nationality.value}
                                  onChange={(e) => validateTextChange(e, "Nationality")} />
                                <div className="errorMsg">{Nationality.error}</div>
                              </Box>
                            </Grid>

                          </Grid>
                        </Grid>
                        <Grid item xs={12} sm={4} md={4}>
                          <Grid container spacing={2} sx={{ mt: 0 }}>
                            <Grid item xs={12} sm={12} md={12} className='uploadGrid'>
                              <Box sx={{ display: 'flex', flexDirection: 'column', p: 1, mt: -1 }}>
                                <FileUpload />
                              </Box>
                            </Grid>
                            <Grid item xs={12} sm={12} md={12}>
                              <Box sx={{ display: 'flex', flexDirection: 'row',gap:'10px', alignItems:'center', p: 1, mt: -1 }}>
                                <Typography className="list-item-text" variant="body1" >
                                  {t('gender')}
                                </Typography>
                                <Box sx={{ display: 'flex', justifyContent: 'left', alignItems: 'center', mt: 0 }}>
                                  <RadioGroup defaultValue="outlined" name="radio-buttons-group" row aria-label="gender" >
                                    {GenderArray.map(
                                      (item) =>
                                        item.name && (
                                          <FormControlLabel
                                            key={item.id} value={item.id}
                                            className="gender-opt"
                                            control={
                                              <Radio
                                                disableRipple
                                                color="default"
                                                className="gender-opt-radio"
                                                checkedIcon={<BpCheckedIcon />}
                                                icon={<BpIcon />} />}
                                            label={item.name}
                                            onClick={() => HandlePaymentClick(item, "Gender")}
                                          />))}
                                  </RadioGroup>
                                </Box>                                
                              </Box>
                              <div className="errorMsg" >{Gender.error}</div>
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>

                  <Grid container spacing={2} className="grid">
                    <Grid item xs={12}>
                      <Grid container spacing={2} sx={{ mt: 0 }}>
                        <Grid item xs={12} sm={4} md={4}>
                          <Box sx={{ display: 'flex', flexDirection: 'column', p: 1, mt: -1 }}>
                            <Typography className="list-item-text" variant="body1">
                              {t('national id')}
                            </Typography>
                            <TextField
                              type="text"
                              size="small"
                              value={NationalId.value}
                              onChange={(e) => validateTextChange(e, "NationalId")} />
                            <div className="errorMsg">{NationalId.error}</div>
                          </Box>
                        </Grid>
                        <Grid item xs={12} sm={8} md={8}>
                          <Box sx={{ display: 'flex', flexDirection: 'column', p: 1, mt: -1 }}>
                            <Typography className="list-item-text" variant="body1">
                              {t('email address')}
                            </Typography>
                            <TextField
                              type="text"
                              size="small"
                              value={Email.value}
                              onChange={(e) => validateTextChange(e, "Email")} />
                            <div className="errorMsg">{Email.error}</div>
                          </Box>
                        </Grid>

                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid container spacing={2} className="grid">
                    <Grid item xs={12}>
                      <Grid container spacing={2} sx={{ mt: 0 }}>
                        <Grid item xs={12} sm={6} md={6}>
                          <Box sx={{ display: 'flex', flexDirection: 'column', p: 1, mt: -1 }}>
                            <Typography className="list-item-text" variant="body1">
                              {t('special peculiarities')}
                            </Typography>
                            <TextField
                              type="text"
                              size="small"
                              rows={4}
                              multiline
                              value={SpecialPeculiarities.value}
                              onChange={(e) => validateTextChange(e, "SpecialPeculiarities")} />
                            <div className="errorMsg">{SpecialPeculiarities.error}</div>
                          </Box>
                        </Grid>
                        <Grid item xs={12} sm={6} md={6}>
                          <Box sx={{ display: 'flex', flexDirection: 'column', p: 1, mt: -1 }}>
                            <Typography className="list-item-text" variant="body1">
                              {t('present address')}
                            </Typography>
                            <TextField
                              type="text"
                              size="small"
                              rows={4}
                              multiline
                              value={PresentAddress.value}
                              onChange={(e) => validateTextChange(e, "PresentAddress")} />
                            <div className="errorMsg">{PresentAddress.error}</div>
                          </Box>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid container spacing={2} className="grid">
                    <Grid item xs={12}>
                      <Grid container spacing={2} sx={{ mt: 0 }}>
                        <Grid item xs={12} sm={4} md={4}>
                          <Box sx={{ display: 'flex', flexDirection: 'column', p: 1, mt: -1 }}>
                            <Typography className="list-item-text" variant="body1">
                              {t('color of eyes')}
                            </Typography>
                            <TextField
                              type="text"
                              size="small"
                              value={ColorOfEyes.value}
                              onChange={(e) => validateTextChange(e, "ColorOfEyes")} />
                            <div className="errorMsg">{ColorOfEyes.error}</div>
                          </Box>
                        </Grid>
                        <Grid item xs={12} sm={4} md={4}>
                          <Box sx={{ display: 'flex', flexDirection: 'column', p: 1, mt: -1 }}>
                            <Typography className="list-item-text" variant="body1">
                              {t('height')}
                            </Typography>
                            <TextField
                              type="text"
                              size="small"
                              value={Height.value}
                              onChange={(e) => validateTextChange(e, "Height")} />
                            <div className="errorMsg">{Height.error}</div>
                          </Box>
                        </Grid>
                        <Grid item xs={12} sm={4} md={4}>
                          <Box sx={{ display: 'flex', flexDirection: 'column', p: 1, mt: -1 }}>
                            <Typography className="list-item-text" variant="body1">
                              {t('occupation')}
                            </Typography>
                            <TextField
                              type="text"
                              size="small"
                              value={Occupation.value}
                              onChange={(e) => validateTextChange(e, "Occupation")} />
                            <div className="errorMsg">{Occupation.error}</div>
                          </Box>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid container spacing={2} className="grid">
                    <Grid item xs={12}>
                      <Grid container spacing={2} sx={{ mt: 0 }}>
                        <Grid item xs={12} sm={4} md={4}>
                          <Box sx={{ display: 'flex', flexDirection: 'column', p: 1, mt: -1 }}>
                            <Typography className="list-item-text" variant="body1">
                              {t('country')}
                            </Typography>
                            <Select
                              value={Country1.value}
                              size="small"
                              onChange={(e) => HandleCountryChange(e.target.value, 'Country1')} >
                              <MenuItem value="">
                                <em>{t("choose a country option")}</em>
                              </MenuItem>
                              {countries.map((e, key) => {
                                return (
                                  <MenuItem key={key} value={e.label}>
                                    {e.label}
                                  </MenuItem>
                                );
                              })}
                            </Select>
                            <div className="errorMsg">{Country1.error}</div>
                          </Box>
                        </Grid>
                        <Grid item xs={12} sm={4} md={4}>
                          <Box sx={{ display: 'flex', flexDirection: 'column', p: 1, mt: -1 }}>
                            <Typography className="list-item-text" variant="body1">
                              {t('phone number')}
                            </Typography>
                            <TextField
                              type="text"
                              size="small"
                              value={PhoneNumber.value}
                              onChange={(e) => validateTextChange(e, "PhoneNumber")} />
                            <div className="errorMsg">{PhoneNumber.error}</div>
                          </Box>
                        </Grid>
                        <Grid item xs={12} sm={4} md={4}>
                          <Box sx={{ display: 'flex', flexDirection: 'column', p: 1, mt: -1 }}>
                            <Typography className="list-item-text" variant="body1">
                              {t('district')}
                            </Typography>
                            <TextField
                              type="text"
                              size="small"
                              value={District1.value}
                              onChange={(e) => validateTextChange(e, "District1")} />
                            <div className="errorMsg">{District1.error}</div>
                          </Box>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>}
              {Tab.placeOfOrigin && <Grid container spacing={2} className="grid passportPage placeorgin">
                <Grid item xs={12}>
                  <FormLabel className="grid-text">{t('applicant place of origin')}</FormLabel>
                  <Grid container spacing={2} className="grid">
                    <Grid item xs={12}>
                      <Grid container spacing={2} sx={{ mt: 0 }}>
                        <Grid item xs={12} sm={4} md={4}>
                          <Box sx={{ display: 'flex', flexDirection: 'column', p: 1, mt: -1 }}>
                            <Typography className="list-item-text" variant="body1">
                              {t('village town')}
                            </Typography>
                            <TextField
                              type="text"
                              size="small"
                              value={VillageTown.value}
                              onChange={(e) => validateTextChangeTab2(e, "VillageTown")} />
                            <div className="errorMsg">{VillageTown.error}</div>
                          </Box>
                        </Grid>
                        <Grid item xs={12} sm={4} md={4}>
                          <Box sx={{ display: 'flex', flexDirection: 'column', p: 1, mt: -1 }}>
                            <Typography className="list-item-text" variant="body1">
                              {t('ta')}
                            </Typography>
                            <TextField
                              type="text"
                              size="small"
                              value={TA.value}
                              onChange={(e) => validateTextChangeTab2(e, "TA")} />
                            <div className="errorMsg">{TA.error}</div>
                          </Box>
                        </Grid>
                        <Grid item xs={12} sm={4} md={4}>
                          <Box sx={{ display: 'flex', flexDirection: 'column', p: 1, mt: -1 }}>
                            <Typography className="list-item-text" variant="body1">
                              {t('district')}
                            </Typography>
                            <TextField
                              type="text"
                              size="small"
                              value={ApplicantDistrict.value}
                              onChange={(e) => validateTextChangeTab2(e, "ApplicantDistrict")} />
                            <div className="errorMsg">{ApplicantDistrict.error}</div>
                          </Box>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid container spacing={2} className="grid">
                    <Grid item xs={12}>
                      <Grid container spacing={2} sx={{ mt: 0 }}>
                        <Grid item xs={12} sm={4} md={4}>
                          <Box sx={{ display: 'flex', flexDirection: 'column', p: 1, mt: -1 }}>
                            <Typography className="list-item-text" variant="body1">
                              {t('country')}
                            </Typography>
                            <Select
                              value={ApplicantCountry.label}
                              size="small"
                              onChange={(e) => HandleCountryChange(e.target.value, 'ApplicantCountry')} >
                              <MenuItem value="">
                                <em>{t("choose a country option")}</em>
                              </MenuItem>
                              {countries.map((e, key) => {
                                return (
                                  <MenuItem key={key} value={e.label}>
                                    {e.label}
                                  </MenuItem>
                                );
                              })}
                            </Select>
                            <div className="errorMsg">{ApplicantCountry.error}</div>
                          </Box>
                        </Grid>
                        <Grid item xs={12} sm={8} md={8}>
                          <Box sx={{ display: 'flex', flexDirection: 'column', p: 1, mt: -1 }}>
                            <Typography className="list-item-text mb-0 radioTxt" variant="body1">
                              {t('malawi citizenship')}
                            </Typography>
                            <Box className='bxRadio' sx={{ display: 'flex', justifyContent: 'left', alignItems: 'center', mt: 0 }}>
                              <RadioGroup defaultValue="outlined" name="radio-buttons-group" row aria-label="gender" >
                                {MalawiCitizenShipArray.map(
                                  (item) =>
                                    item.name && (
                                      <FormControlLabel
                                        key={item.id} value={item.id}
                                        className="gender-opt"
                                        control={
                                          <Radio
                                            disableRipple
                                            color="default"
                                            className="gender-opt-radio"
                                            checkedIcon={<BpCheckedIcon />}
                                            icon={<BpIcon />} />}
                                        label={item.name}
                                        onClick={() => HandlePaymentClick(item, "MalawiCitizenShip")}
                                      />))}
                              </RadioGroup>
                            </Box>
                            <div className="errorMsg">{MalawiCitizenShip.error}</div>
                          </Box>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                  <FormLabel className="grid-text">{t('father place of origin')}</FormLabel>
                  <Grid container spacing={2} className="grid">
                    <Grid item xs={12}>
                      <Grid container spacing={2} sx={{ mt: 0 }}>
                        <Grid item xs={12} sm={6} md={6}>
                          <Box sx={{ display: 'flex', flexDirection: 'column', p: 1, mt: -1 }}>
                            <Typography className="list-item-text" variant="body1">
                              {t('village ta')}
                            </Typography>
                            <TextField
                              type="text"
                              size="small"
                              value={FatherVillageTA.value}
                              onChange={(e) => validateTextChangeTab2(e, "FatherVillageTA")} />
                            <div className="errorMsg">{FatherVillageTA.error}</div>
                          </Box>
                        </Grid>
                        <Grid item xs={12} sm={6} md={6}>
                          <Box sx={{ display: 'flex', flexDirection: 'column', p: 1, mt: -1 }}>
                            <Typography className="list-item-text" variant="body1">
                              {t('country')}
                            </Typography>
                            <Select
                              value={FatherCountry.label}
                              size="small"
                              onChange={(e) => HandleCountryChange(e.target.value, 'FatherCountry')} >
                              <MenuItem value="">
                                <em>{t("choose a country option")}</em>
                              </MenuItem>
                              {countries.map((e, key) => {
                                return (
                                  <MenuItem key={key} value={e.label}>
                                    {e.label}
                                  </MenuItem>
                                );
                              })}
                            </Select>
                            <div className="errorMsg">{FatherCountry.error}</div>
                          </Box>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid container spacing={2} className="grid">
                    <Grid item xs={12}>
                      <Grid container spacing={2} sx={{ mt: 0 }}>
                        <Grid item xs={12} sm={6} md={6}>
                          <Box sx={{ display: 'flex', flexDirection: 'column', p: 1, mt: -1 }}>
                            <Typography className="list-item-text" variant="body1">
                              {t('district')}
                            </Typography>
                            <TextField
                              type="text"
                              size="small"
                              value={FatherDistrict.value}
                              onChange={(e) => validateTextChangeTab2(e, "FatherDistrict")} />
                            <div className="errorMsg">{FatherDistrict.error}</div>
                          </Box>
                        </Grid>
                        <Grid item xs={12} sm={6} md={6}>
                          <Box sx={{ display: 'flex', flexDirection: 'column', p: 1, mt: -1 }}>
                            <Typography className="list-item-text" variant="body1">
                              {t('nId minor application')}
                            </Typography>
                            <TextField
                              type="text"
                              size="small"
                              value={FatherNationalId.value}
                              onChange={(e) => validateTextChangeTab2(e, "FatherNationalId")} />
                            <div className="errorMsg">{FatherNationalId.error}</div>
                          </Box>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                  <FormLabel className="grid-text">{t('mother place of origin')}</FormLabel>
                  <Grid container spacing={2} className="grid">
                    <Grid item xs={12}>
                      <Grid container spacing={2} sx={{ mt: 0 }}>
                        <Grid item xs={12} sm={6} md={6}>
                          <Box sx={{ display: 'flex', flexDirection: 'column', p: 1, mt: -1 }}>
                            <Typography className="list-item-text" variant="body1">
                              {t('village ta')}
                            </Typography>
                            <TextField
                              type="text"
                              size="small"
                              value={MotherVillageTA.value}
                              onChange={(e) => validateTextChangeTab2(e, "MotherVillageTA")} />
                            <div className="errorMsg">{MotherVillageTA.error}</div>
                          </Box>
                        </Grid>
                        <Grid item xs={12} sm={6} md={6}>
                          <Box sx={{ display: 'flex', flexDirection: 'column', p: 1, mt: -1 }}>
                            <Typography className="list-item-text" variant="body1">
                              {t('country')}
                            </Typography>
                            <Select
                              value={MotherCountry.label}
                              size="small"
                              onChange={(e) => HandleCountryChange(e.target.value, 'MotherCountry')} >
                              <MenuItem value="">
                                <em>{t("choose a country option")}</em>
                              </MenuItem>
                              {countries.map((e, key) => {
                                return (
                                  <MenuItem key={key} value={e.label}>
                                    {e.label}
                                  </MenuItem>
                                );
                              })}
                            </Select>
                            <div className="errorMsg">{MotherCountry.error}</div>
                          </Box>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid container spacing={2} className="grid">
                    <Grid item xs={12}>
                      <Grid container spacing={2} sx={{ mt: 0 }}>
                        <Grid item xs={12} sm={6} md={6}>
                          <Box sx={{ display: 'flex', flexDirection: 'column', p: 1, mt: -1 }}>
                            <Typography className="list-item-text" variant="body1">
                              {t('district')}
                            </Typography>
                            <TextField
                              type="text"
                              size="small"
                              value={MotherDistrict.value}
                              onChange={(e) => validateTextChangeTab2(e, "MotherDistrict")} />
                            <div className="errorMsg">{MotherDistrict.error}</div>
                          </Box>
                        </Grid>
                        <Grid item xs={12} sm={6} md={6}>
                          <Box sx={{ display: 'flex', flexDirection: 'column', p: 1, mt: -1 }}>
                            <Typography className="list-item-text" variant="body1">
                              {t('nId minor application')}
                            </Typography>
                            <TextField
                              type="text"
                              size="small"
                              value={MotherNationalId.value}
                              onChange={(e) => validateTextChangeTab2(e, "MotherNationalId")} />
                            <div className="errorMsg">{MotherNationalId.error}</div>
                          </Box>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                  <FormLabel className="grid-text center consenthead">{t('parent or gaurdian consent')}</FormLabel>
                  <Grid container spacing={2} className="grid">
                    <Grid item xs={12}>
                      <Grid container spacing={2} sx={{ mt: 0 }}>
                        <Grid item xs={12} sm={12} md={12}>
                          <Box sx={{ display: 'flex', flexDirection: 'column', p: 1, mt: -1 }}>
                            <FormControl className="form-group nopaddForm consentSec">
                              <label className="agrreCheck">
                                <input
                                  className="round-checkbox"
                                  type="checkbox"
                                  checked={consent.value}
                                  onChange={(e) => validateTextChangeTab2(e, "consent")}
                                />
                                <span className="checkmark">{t('consent declare')}</span>
                              </label>
                            </FormControl>
                            <div className="errorMsg">{consent.error}</div>
                          </Box>
                        </Grid>
                        <Grid item xs={12} sm={6} md={6}>
                          <Box sx={{ display: 'flex', flexDirection: 'column', p: 1, mt: -1 }}>
                            <Typography className="list-item-text" variant="body1">
                              {t('fullnames of minor')}
                            </Typography>
                            <TextField
                              type="text"
                              size="small"
                              value={fullNameMinor.value}
                              onChange={(e) => validateTextChangeTab2(e, "fullNameMinor")} />
                            <div className="errorMsg">{fullNameMinor.error}</div>
                          </Box>
                        </Grid>
                        <Grid item xs={12} sm={6} md={6}>
                          <Box sx={{ display: 'flex', flexDirection: 'column', p: 1, mt: -1 }}>
                            <Typography className="list-item-text" variant="body1">
                              {t('relationship to minor')}
                            </Typography>
                            <TextField
                              type="text"
                              size="small"
                              value={relationshipMinor.value}
                              onChange={(e) => validateTextChangeTab2(e, "relationshipMinor")} />
                            <div className="errorMsg">{relationshipMinor.error}</div>
                          </Box>
                        </Grid>
                        <Grid item xs={12} sm={12} md={12}>
                          <Box sx={{ display: 'flex', flexDirection: 'column', p: 1, mt: -1 }}>
                            <Typography className="list-item-text hintColor" variant="body1">
                              {t('consent hint')}
                            </Typography>
                          </Box>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>}
              {Tab.declaration && <Grid container spacing={2} className="grid passportPage">
                <Grid item xs={12}>
                  {/* <FormLabel className="grid-text">{t('applicant place of origin')}</FormLabel> */}
                  <Grid container spacing={2} className="grid">
                    <Grid item xs={12}>
                      <Grid container spacing={2} sx={{ mt: 0 }}>
                        <Grid item xs={12} sm={12} md={12}>
                          <Box sx={{ display: 'flex', flexDirection: 'column', p: 1, mt: -1 }}>
                            <FormControl className="form-group nopaddForm">
                              <label className="agrreCheck">
                                <input
                                  className="round-checkbox"
                                  type="checkbox"
                                  checked={agreed.value}
                                  onChange={validateTextChangeTab3}
                                />
                                <span className="checkmark">I Declared that</span>
                              </label>
                            </FormControl>
                            <div className="errorMsg">{agreed.error}</div>
                          </Box>
                        </Grid>
                        <Grid item xs={12} sm={12} md={12}>
                          <Box sx={{ display: 'flex', flexDirection: 'column', p: 1, mt: -1 }}>
                            <Typography className="list-item-text" variant="body1">
                              {t('declareText1')}
                            </Typography>
                          </Box>
                        </Grid>
                        <Grid item xs={12} sm={12} md={12}>
                          <Box sx={{ display: 'flex', flexDirection: 'column', p: 1, mt: -1, justifyContent: 'center', alignItems: 'center' }}>
                            <Typography className="list-item-text" variant="body1">
                              {t('orText')}
                            </Typography>
                          </Box>
                        </Grid>
                        <Grid item xs={12} sm={12} md={12}>
                          <Box sx={{ display: 'flex', flexDirection: 'column', p: 1, mt: -1 }}>
                            <Typography className="list-item-text" variant="body1">
                              {t('declareText2')}
                            </Typography>
                          </Box>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>}
              <div className="tab-next-btn" style={{ flexDirection: 'row', marginBottom: '30px' }}>
                {Tab.personalInformation ? null : <LoadingButton
                  loadingIndicator="Loading…"
                  onClick={HandleBackClick}
                  variant="contained" >
                  <span className="arrowIcon-text"> <KeyboardBackspaceIcon /> {t("back")}</span>
                </LoadingButton>}
                <LoadingButton
                  sx={{ ml: '30px' }}
                  loadingIndicator="Loading…"
                  onClick={HandleNextClick}
                  variant="contained" >
                  <span className="arrowIcon-text nextsvg">{Tab.declaration ? t("submit") : t("next")} <KeyboardBackspaceIcon /></span>
                </LoadingButton>
              </div>
            </div>
          </div>
          

        </section>
        <Dialog
          open={open}
          onClose={handleClose}
          scroll={scroll}
          aria-labelledby="scroll-dialog-title"
          aria-describedby="scroll-dialog-description"
          className="notify-popup"
        >
          <DialogTitle id="scroll-dialog-title" className="head">
            {popupText?.message ?? ''}
          </DialogTitle>
          <DialogContent dividers={scroll === "paper"}>
            <DialogContentText
              id="scroll-dialog-description"
              ref={descriptionElementRef}
              tabIndex={-1} >
              <div className="desc">{("Your application submitted successfully. Below is your application reference number. Application Ref No: " + popupText?.data?.app_no) ?? ''}</div>
            </DialogContentText>
          </DialogContent>
          <DialogActions className="closebtn">
            <Button onClick={handleClose} className="notify-close">
              {t("close text")}
            </Button>
          </DialogActions>
        </Dialog>
      </Container>
    </div>
  );
}

export default PassportService;
