import React, { useState, useEffect, useContext } from "react";
import axios from "axios";
import { APP_LOGOUT } from "../../utils/api-constant";
import { useNavigate } from "react-router-dom";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import user from "../../assets/Img/user.png";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import EmailIcon from "@mui/icons-material/Email";
import KeyIcon from "@mui/icons-material/Key";
import LogoutIcon from "@mui/icons-material/Logout";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Typography from "@mui/material/Typography";
import AuthContext from "../../context/AuthContext";
import { useTranslation } from "react-i18next";

const MyAccountMenu = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const authCtx = useContext(AuthContext);
  const [anchorEl, setAnchorEl] = useState(null);
  const [logoutResp, setLogoutResponse] = useState("");
  const [openModal, setOpenModal] = useState(false);
  const [fname, setFirstName] = useState("");
  const [apiToken, setApiToken] = useState("");
  const handleCloseModal = () => {
    setOpenModal(false);
  };

  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (from) => {
    setAnchorEl(null);
    if (from === "myprofile"){
      navigate("my-account/dashboard");
    } else if (from === "changeemail") {
      navigate("my-account/change-mail");
    } else if (from === "changepassword"){
      navigate("my-account/change-password");
    } else if (from === "logout") {
      Logout();
    }
  };

  useEffect(() => {
    if (
      localStorage.getItem("apiToken") !== "undefined" &&
      localStorage.getItem("apiToken") !== "" &&
      localStorage.getItem("apiToken") !== null
    ) {
      let apiToken = JSON.parse(localStorage.getItem("apiToken"));
      let loginData = JSON.parse(localStorage.getItem("userDetails"));
      setFirstName(loginData.fname);
      setApiToken(apiToken);
    }
  }, []);

  function Logout() {
    const params = {
      headers: {
        needJson: 1,
        apiToken: apiToken,
      },
    };
    axios
      .get(process.env.REACT_APP_HOST_URL + APP_LOGOUT, params)
      .then((response) => {
        setLogoutResponse(response.data.message);
        setOpenModal(true);
        if (response.data.success) {
          localStorage.removeItem("apiToken");
          localStorage.removeItem("userDetails");
          authCtx.authLogout();
          navigate("/");
        }
      });
  }
  return (
    <>
      <div
        className="head-my"
        aria-controls={open ? "basic-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleClick}
      >
        <div className="left-text">
          <p className="user-name">
          <span className="hi_name">{t("hi text")}, {fname}</span> <ArrowDropDownIcon />
          </p>
        </div>
        <div className="right-img">
          <img src={user} />
        </div>
      </div>
      <Menu
        className="my-acc-drop"
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={() => handleClose("close")}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        <MenuItem
          onClick={() => handleClose("myprofile")}
        >
          <AccountCircleIcon /> {t("my profile title")}
        </MenuItem>
        <MenuItem
          onClick={() => handleClose("changeemail")}
        >
          <EmailIcon /> {t("change mail text")}
        </MenuItem>
        <MenuItem
          onClick={() => handleClose("changepassword")}
        >
          <KeyIcon /> {t("change password text")}
        </MenuItem>
        <MenuItem
          onClick={() => handleClose("logout")}
        >
          <LogoutIcon /> {t("logout text")}
        </MenuItem>
      </Menu>
      <Modal
        open={openModal}
        onClose={handleCloseModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box className="modal-popup">
          <Typography id="modal-modal-title" variant="h6" component="h2">
            {logoutResp}
          </Typography>
        </Box>
      </Modal>
    </>
  );
};

export default MyAccountMenu;
