import { FormGroup, FormLabel, Grid, MenuItem, Select } from "@mui/material";
import React, { useContext, useEffect, useState, useRef } from "react";
import AuthContext from "../../../context/AuthContext";
import useApiFetch from "../../../hooks/useApiFetch";
import { Activity_LOG, SERVICE_LISTALL } from "../../../utils/api-constant";
import "./Activity.css";
import { Link, useNavigate } from "react-router-dom";
import FormLoader from "../../Loader/FormLoader";
import axios from "axios";
import { useTranslation } from "react-i18next";
import { isMobile, replaceSpaceWithDash } from "../../../utils/custom";

const MyActivity = () => {
  const { t } = useTranslation();
  const authCtx = useContext(AuthContext);
  const [activityData, setAcivityData] = useState([]);
  const [userId, setuserId] = useState("");
  const [loading, setloading] = useState(false);
  const [error, seterror] = useState("");
  const [SearchText, setSearchText] = useState("");
  const itemRef = useRef(null);

  const navigate = useNavigate();
  useEffect(() => {
    if (!authCtx.isLogin) {
      navigate("/");
    }
    let userInfoData = JSON.parse(localStorage.getItem("userDetails"));
    setuserId(userInfoData.id);
    getFilterService(userInfoData.id, "");
    //console.log(isMobile());
    if (isMobile()) {
      if (itemRef.current) {
        itemRef.current.scrollIntoView({ behavior: 'smooth' });
      }
    }
  }, [authCtx.isLogin, itemRef]);

  /*const { data, loading, error } = useApiFetch(
    process.env.REACT_APP_HOST_URL + Activity_LOG +`?user_id=${userId}`
  );*/
  const {
    data: listData,
    loading: listLoading,
    error: listError,
  } = useApiFetch(process.env.REACT_APP_HOST_URL + SERVICE_LISTALL);

  const getFilterService = async (userId, value) => {
    console.log(value);
    setSearchText(value);
    setloading(true);
    if (value === "all") {
      value = "";
    }
    const token = JSON.parse(localStorage.getItem("apiToken"));
    const params = {
      headers: {
        "Content-Type": "application/json",
        needJson: 1,
        apiToken: token,
      },
    };
    await axios
      .get(
        process.env.REACT_APP_HOST_URL +
        Activity_LOG +
        `?service_name=${value}&user_id=${userId}`,
        params
      )
      .then((res) => {
        setloading(false);
        setAcivityData(res.data.list);
      })
      .catch((e) => {
        setloading(false);
        console.log(e.message);
      });
  };

  if (error || listError) return console.log(error);
  
  
  const formetDateTime = (value) => {
    var date = new Date(value);
    const yyyy = date.getFullYear();
    let mm = date.getMonth() + 1;
    let dd = date.getDate();
    let hr = date.getHours();
    let min = date.getMinutes();
    var ampm = "AM";

    if (hr >= 12) {
      ampm = "PM";
    }

    if (hr > 12) {
      hr = hr - 12;
    }

    if (dd < 10) dd = "0" + dd;
    if (mm < 10) mm = "0" + mm;
    const formattedToday =
      dd + "-" + mm + "-" + yyyy + " " + hr + ":" + min + " " + ampm;
    return formattedToday;
  };
  console.log(activityData);
  const fisrtCatgory = (cat) => {
    console.log("cat--> "+JSON.stringify(cat));
    const array = cat.split(",");
    const category = replaceSpaceWithDash(array[0]);
    return category;
  };

  const HandleNavigation = (item) => {
    /*  */
    console.log("item--> ", item)
    if (item.service_type === 1 || item.service_type === "1") {
      if (item.reference_number !== null && item.reference_number !== "") {
        if (authCtx.isLogin) {
          navigate(`/all-services-detail/${replaceSpaceWithDash(item.service_name)}`, { state: { mdaName: item.mda_name, serviceId: item.reference_number } })
        } else {
          navigate("/login", { state: { from: 3, navigateUrl: `/all-services-detail/${replaceSpaceWithDash(item.service_name)}`, mdaName: item.mda_name, serviceId: item.reference_number } });
        }
      } else {
        if (authCtx.isLogin) {
          navigate(`/all-services-detail/${replaceSpaceWithDash(item.service_name)}`, { state: { mdaName: item.mda_name, serviceId: item.id } })
        } else {
          navigate("/login", { state: { from: 3, navigateUrl: `/all-services-detail/${replaceSpaceWithDash(item.service_name)}`, mdaName: item.mda_name, serviceId: item.id } });
        }
        
      }
    } else {
      if (item.service_id === 1 || item.service_id === 2 || item.service_id === 3 || item.service_id === 4 || item.service_id === 5) {
        navigate(`/detail/mda/${fisrtCatgory(item.mda_name)}/${replaceSpaceWithDash(item.service_name)}`, { state: { anonymous_user: 1, } })
      }
    }
  };
  
  return (
    (loading || listLoading)
      ? <div ref={itemRef} className="account-formSection notify-list-page form-loading">
        <FormLoader />
      </div>
      : <div ref={itemRef} className="account-formSection notify-list-page">
      <h3 className="head-h3">{t("my activity text")}</h3>
      <div className="activity-detail">
        {listData.list.length > 0 && (
          <Grid>
            <Grid item xs={12} sm={6}>
              <FormGroup className="form-group">
                <FormLabel>{t("service label")}</FormLabel>
                <Select onChange={(e) => getFilterService(userId, e.target.value)} value={SearchText}>
                  <MenuItem value="all">{t("All")}</MenuItem>
                  {listData.list.map((item) => (
                    <MenuItem value={item.name} key={item.name}>
                      {item.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormGroup>
            </Grid>
          </Grid>
        )}
        {activityData &&
          activityData.map((item, index) => (
            <div style={{ textDecoration: 'none', cursor: 'pointer' }} onClick={() => HandleNavigation(item)}>
            <div className="activity-list" key={index}>
              <div className="top">
                <div className="name">
                  {t("service label")}: <span>{item.service_name}</span>
                </div>
                <div className="date">{formetDateTime(item.date)}</div>
              </div>
              <div className="mid">{item.description}</div>
              <div className="bot">
                <div className="name">
                {t("via label")}: <span>{item.user_agent}</span>
                </div>
                <div className="status">
                  {item.status === "Completed" && (
                    <span className="completed">{item.status}</span>
                  )}
                  {item.status === "Pending" && (
                    <span className="pending">{item.status}</span>
                  )}
                    {item.status === "Incomplete" && (
                    <span className="faild">{item.status}</span>
                  )}
                   {item.status === "Processing" && (
                    <span className="pending">{item.status}</span>
                  )}
                </div>
              </div>
            </div>
            </div>
          ))}
        {activityData && activityData.length === 0 && (
          <div className="no-data-found">
            <br />
            {t("no data text")}
          </div>
        )}
      </div>
    </div>
  );
};

export default MyActivity;
