import React, { useCallback, useState } from "react";
import { useDropzone } from "react-dropzone";
import { CloudUpload } from "@mui/icons-material";
import { Box, Typography } from "@mui/material";

const FileUpload = () => {
  const onDrop = useCallback((acceptedFiles) => {    
    console.log("Files uploaded: ", acceptedFiles);
  }, []);

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: "image/*", 
  });

  const [image, setImage] = useState(null);

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        setImage(e.target.result); // Set the image data URL
      };
      reader.readAsDataURL(file); // Read the file as a data URL
    }
  };

  return (
    <Box
        className='uploadBox'
      sx={{
        
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
        cursor: "pointer",
        textAlign: "center",
        position: "relative",
        overflow: "hidden",
      }}
    >
      {!image ? (
        <>
          <input
            type="file"
            accept="image/*"
            onChange={handleFileChange}
            style={{
              position: "absolute",
              width: "100%",
              height: "100%",
              opacity: 0,
              cursor: "pointer",
            }}
          />
          <CloudUpload sx={{ fontSize: 40, color: "#3f51b5" }} />
          <Typography variant="h6" className="uploadTxt" sx={{ marginTop: 1 }}>
            Upload Your Photo
          </Typography>
        </>
      ) : (
        <img
          src={image}
          alt="Uploaded"
          style={{
            width: "100%",
            height: "100%",
            objectFit: "cover",
          }}
        />
      )}
    </Box>
  );
};

export default FileUpload;
