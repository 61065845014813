import React, { useState, useContext } from "react";
import "./Banner.css";
import { GET_BANNER_LIST } from "../../../utils/api-constant";
import bannerImg from "../../../assets/Img/Banner1.jpg";
import Carousel from "react-material-ui-carousel";
import useApiFetch from "../../../hooks/useApiFetch";
import LoadingBanner from "../../Loader/LoadingBanner";
import { Link, useNavigate } from "react-router-dom";
import EastIcon from '@mui/icons-material/East';
import HomeBanner from "../../../assets/Img/bannerscheme.jpg";
import { TextField, InputAdornment, IconButton } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import { useTranslation } from "react-i18next";

function Banner({ type, banner_name }) {
  // const { data, loading, error } = useApiFetch(
  //   process.env.REACT_APP_HOST_URL +
  //     GET_BANNER_LIST +
  //     `?type=${type}&banner_name=${banner_name}`
  // );
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [searchValue, setSearchValue] = useState("");
  const { data, loading, error } = useApiFetch(
    process.env.REACT_APP_HOST_URL +
      GET_BANNER_LIST +
      `?type=${type}`
  );
  if (loading) return <LoadingBanner />;
  const banners = data.list;

  const handleSearchChange = (value) => {
    setSearchValue(value);
  };

  const handleEndSearchClick = () => {
    console.log("searchValue--> ", searchValue);
    navigate("/all-services", { state: { searchText: searchValue } })
  };

  const primaryColor = window.getComputedStyle(document.documentElement).getPropertyValue('--primary');

  return (
    <section className="BannerSection">
      {/* <Carousel navButtonsAlwaysVisible={true}>
        { typeof banners != "undefined" ? (
          banners?.map((item) => (
            <div key={item.id} style={{ position: 'relative' }}>
            <img src={ process.env.REACT_APP_HOST_URL + process.env.REACT_APP_IMAGE_URL + item.banner_image } alt="banner" />
            {item.banner_description && (
              <div className="center_banner_txt" style={{ position: 'absolute', top: '50%', left: '3%', transform: 'translate(0%, -50%)', textAlign: 'left', color: 'white' }}>
                <span class="badge">Ministry of Education</span>
                {item.banner_description && (
                  <h2> {item.banner_description} </h2>
                )}
                {item.button_description && (
                  <p> {item.button_description} </p>
                )}
                {item.button_label && (
                  <Link to={item.button_link} style={{ top: '65%'}}>{item.button_label} <EastIcon/></Link>
                )}
              </div>
              // <div class="banner-container">
              //     <div class="banner-text">
              //         <span class="badge">Ministry of Education</span>
              //         <h1>{item.banner_description} </h1>
              //         <p>{item.button_description}</p>
              //         <Link to={item.button_link} className="read-more" style={{ top: '65%'}}>{item.button_label}</Link>
              //     </div>
              // </div>
            )}
            </div>
          )) )
        : ( <img src={bannerImg} alt="banner" /> )}
    </Carousel> */}
      <div style={{ position: 'relative', }}>
        <img src={HomeBanner} alt="banner" style={{ height: 350, objectFit: 'cover', width: "100%" }}/>
        <div className="banner_bg">
          <div className="search-content">
          <div className="welcome_text bottom_sp">{t("welcome to bomalathu")}</div>
          <TextField
            placeholder={t("search service placeholder")}
            className="search_wtd"
          style={{ backgroundColor: 'white' }}
            onChange={(e) => handleSearchChange(e.target.value)}
            type="text"
            size="small"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />                  
                </InputAdornment>
              ),
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton className="search_btn_ban" onClick={handleEndSearchClick} style={{ backgroundColor: primaryColor, borderRadius: '6px', padding: "6px", marginRight: "-6px", marginLeft: "-15px" }}>
                    {/* <SearchIcon style={{ color: "white", width: 20, height: 20 }} /> */}
                    <span className="color_txt"> {t("search ttl")}</span>
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
          </div>
        </div>
      </div>
    </section>
  );
}

export default Banner;
